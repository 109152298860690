





















import {Component, Vue} from "vue-property-decorator";
import Layout from '@/components/Layout/Layout.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import {Crumb} from "@/types/Breadcrumbs";
import fetcher from "@/lib/fetcher";
import {sortByNumericField} from "@/lib/sort";

@Component({
  components: { Layout, Breadcrumbs }
})
export default class Faq extends Vue {
  name: string = 'Вопросы и ответы'
  crumbs: Crumb[] = [{title: this.name}]
  questions: Array<{
    id: number,
    question: string,
    answer: string
  }> = []
  isQuestionsFetched: boolean = false

  beforeMount() {
    fetcher.get('/faq/as-list')
      .then(questions => this.questions = Object.assign({}, this.questions, sortByNumericField(questions, 'id')))
      .finally(() => this.isQuestionsFetched = true)
  }
}
