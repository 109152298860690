
import {Component, Vue} from "vue-property-decorator";
import {Page} from "@/types/Page";
import fetcher from "@/lib/fetcher";
import emitter from "@/emitter";

@Component
export default class GridView extends Vue {

  gridEndpoint: string = ''
  isPageFetched = false
  page!: Page<any>
  sortField: string = 'id'
  isNeedSort: boolean = true
  otherQuery: string = ''
  defaultPageSize = 20

  getPageable (): { page: number, size: number } {
    const page = this.$route.query.page ? parseInt(this.$route.query.page.toString(), 10) - 1 : 0
    const size = this.$route.query.size ? parseInt(this.$route.query.size.toString(), 10) : this.defaultPageSize
    return { page, size }
  }

  beforeMount() {
    this.fetchPage()
  }

  fetchPage () {
    this.isPageFetched = false
    const pageable = this.getPageable()
    let url = `${this.gridEndpoint}?size=${pageable.size}&page=${pageable.page}`
    if (this.isNeedSort) url += `&sort=${this.sortField}`
    url += this.otherQuery
    fetcher.get(url).then(result => {
      if (result?.status?.code === 401) {
        emitter.emit('user.logout')
      }
      result.content.map((e: any, key: any) => {
        result.content[key] = this.fetchMapping(e)
      })
      this.page = Object.assign({}, this.page, result)
    }).finally(() => this.isPageFetched = true)
  }

  fetchMapping (entity: {[index: string]: any}): {[index: string]: any} {
    return entity
  }

  reloadPage () { location.reload() }
}
