


















import {Component, Vue} from 'vue-property-decorator'
import fetcher from "@/lib/fetcher";
import {SlideEntity} from "@/types/SlideEntity";
import SlideForm from "@/components/Admin/HomePage/MainSlider/SlideForm.vue";
import {uuid} from "@/lib/string";
import {sortByNumericField} from "@/lib/sort";
import {SelectFormElement} from "@/types/SelectFormElement";
import {VideoType} from "@/types/VideoType";

@Component({
  components: {SlideForm}
})
export default class MainSlider extends Vue {

  slides: Array<SlideEntity> = []
  videoTypes: Array<SelectFormElement> = []
  isSlidesFetched: boolean = false
  isVideoTypesFetched: boolean = false

  mounted () {
    fetcher.get('/home-slider').then(slides => {
      slides.map((slide: any) => {
        slide.uuid = uuid()
        if (slide.background?.id) {
          slide.background.uuid = uuid()
        } else {
          slide.background = {id: null, uuid: uuid()}
        }
        this.slides.push(slide)
      })
      this.slides = Object.assign([], sortByNumericField(this.slides, 'id'))
    }).finally(() => this.isSlidesFetched = true)
    fetcher.get('/video-type').then((types: Array<VideoType>) => {
      types.map(type => this.videoTypes.push({text: String(type.displayValue), value: type.id}))
    }).finally(() => this.isVideoTypesFetched = true)
  }

  async onSlideSubmit(slide: SlideEntity) {
    const form = new FormData()
    const escapeFields = ['background']
    for (let field in slide) {
      // @ts-ignore
      if (slide.hasOwnProperty(field) && escapeFields.indexOf(field) < 0 && slide[field] != null ) {
        // @ts-ignore
        form.append(field, String(slide[field]))
      }
    }
    if (slide.backgroundFile) {
      form.append('background.file', slide.backgroundFile)
    }
    if (slide.background?.id) {
      form.append('background.id', String(parseInt(String(slide.background.id), 10)))
    }
    fetcher.post('/home-slider', {body: form})
        .then(s => {
          slide.id = s.id
          const bgUuid = slide?.background?.uuid
          if (s.background == null) {
            slide.background = {id: null, uuid: bgUuid, url: '', name: '', originalName: '', extension: ''}
          } else {
            slide.background = s.background
            slide.uuid = bgUuid
          }
          this.alertResult(slide.uuid, true)
        }).catch(() => this.alertResult(slide.uuid, false))
  }

  onSlideDelete (slide: SlideEntity) {
    if (slide.id) {
      if (!confirm(`Вы точно хотите удалить слайд c id ${slide.id}`)) return false
      fetcher.delete(`/home-slider/${slide.id}`)
        .then(() => this.slides = this.slides.filter(s => s.uuid != slide.uuid))
    } else {
      this.slides = this.slides.filter(s => s.uuid != slide.uuid)
    }
    return true
  }

  onSlideAdd () {
    this.slides.push({
      id: null,
      link: '',
      title: '',
      uuid: uuid(),
      position: this.getNextSlidePosition(),
      background: { id: null, uuid: uuid(), url: '', name: '', originalName: '', extension: '' },
      description: '',
      isVideo: false,
      videoUrl: null,
      videoTypeId: 1,
    })
  }

  getNextSlidePosition () {
    let maxPosition = 0
    this.slides.map(slide => { if (slide.position > maxPosition) maxPosition = slide.position })
    return maxPosition + 1
  }

  alertResult (uuid: string | undefined | null, isSuccess: boolean) {
    const resultVariant = isSuccess ? 'success' : 'danger'
    const resultText  = isSuccess ? 'Сохранено!': 'Непредвиденная ошибка!'
    const btn = document.querySelector<HTMLInputElement>(`#save-slide-${uuid}`)
    if (btn) {
      const originalClasses = btn.getAttribute('class')
      const originalText = btn.innerText
      const resultClasses = `btn btn-${resultVariant}`
      btn.setAttribute('class', resultClasses)
      btn.innerText = resultText
      setTimeout(() => {
        btn.setAttribute('class', String(originalClasses))
        btn.innerText = originalText
      }, 1500)
    }
  }
}
