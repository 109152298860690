






import {Component, Prop, Vue} from "vue-property-decorator";
import fetcher from "@/lib/fetcher";

@Component
export default class RAW extends Vue {
  @Prop() url: string | undefined

  get formattedUrl () {
    if (this.url?.startsWith('/') && !this.url?.startsWith('//')) {
      return fetcher.hostName + this.url
    }
    return this.url
  }

  mounted() {
    this.$emit('video-mounted')
  }
}
