















































import Vue from 'vue'
import Component from 'vue-class-component';
import Layout from '@/components/Layout/Layout.vue'
import Slider from '@/components/Home/Slider.vue'
import SmallCard from "@/components/Product/SmallCard.vue";
import fetcher from "@/lib/fetcher";
import {ProductType} from "@/types/Product/ProductType";
import {getParams} from "@/lib/params";
import {sortByNumericField} from "@/lib/sort";
import {BannerEntity} from "@/types/BannerEntity";
import SmallBannerModal from "@/components/Home/SmallBannerModal.vue";

@Component({ components: {SmallBannerModal, SmallCard, Layout, Slider } })
export default class Home extends Vue {
  product: ProductType | undefined
  seo: { title: string, text: string } = {title: "", text: ""}
  isProductLoaded: boolean = false
  isSeoLoaded: boolean = false
  isBannersLoaded: boolean = false
  banners: Array<BannerEntity> = []

  async beforeMount() {
    await this.fetchBanners()
    await this.fetchProduct()
    await this.fetchSeoParams()
  }

  async fetchProduct() {
    const result = await fetcher.get('/product/by-code/right-on-target')
    if (result.status.code === 200) {
      this.product = Object.assign({}, this.product, result.data)
      this.isProductLoaded = true
    }
  }

  getBannerIcon (bannerEntity: BannerEntity) {
    return fetcher.hostName + bannerEntity.icon?.url
  }

  getBannerAlt (bannerEntity: BannerEntity) {
    return bannerEntity.icon?.originalName
  }

  private async fetchSeoParams() {
    const keys = ['home-page.seo-title', 'home-page.seo-text']
    getParams(keys).then(params => {
      this.seo.title = params[keys[0]]
      this.seo.text = params[keys[1]]
      this.isSeoLoaded = true
    })
  }

  private async fetchBanners() {
    fetcher.get('/small-banners')
      .then(banners => this.banners = Object.assign({}, this.banners, sortByNumericField(banners, 'sort')))
      .finally(() => this.isBannersLoaded = true)
  }
}
