export default class UserRole {
    id: number | undefined
    name: string | undefined

    constructor (params?: any) {
        if (params.hasOwnProperty('id'))
            this.id = params.id
        if (params.hasOwnProperty('name'))
            this.name = params.name
    }
}
