














import {Component} from "vue-property-decorator";
import GridView from "@/components/Admin/GridView.vue";
import Grid from "@/components/Interface/Grid.vue";

@Component({
  components: {Grid}
})
export default class Pages extends GridView {

  gridEndpoint: string = '/page'

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    return {
      id: entity.id,
      title: entity.title,
      code: entity.code,
    }
  }
}
