











































import emitter from '@/emitter'
import Layout from '@/components/Layout/Layout.vue'
import {Vue, Component, Prop} from 'vue-property-decorator'
import {ErrorMessage, LoginForm} from '@/types/Login'
import fetcher from "@/lib/fetcher";
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import {Crumb} from '@/types/Breadcrumbs'
import {getParam} from "@/lib/params";
import ReCaptcha from "@/components/Interface/ReCaptcha.vue";

@Component({
  components: {ReCaptcha, Layout, Breadcrumbs }
})
export default class Login extends Vue {

  @Prop() back!: string

  hostOrigin: string = location.origin
  howtoRegisterPage: string = ''
  name: string = 'Вход'
  crumbs: Crumb[] = [{title: this.name}]
  error: ErrorMessage = new ErrorMessage()
  form: LoginForm = new LoginForm()
  errorVariant: string = 'danger'

  private throwAuthError (message: string, timeout: number | null = null, variant: string | null = null) {

    this.error.active = true
    this.error.text = message
    const defaultErrorVariant = this.errorVariant
    if (variant) this.errorVariant = variant
    if (timeout === 0) {
      return false
    } else if (timeout && timeout > 0) {
      setTimeout(() => {
        this.error.active = false
        this.error.text = null
        this.errorVariant = defaultErrorVariant
      }, timeout)
    } else {
      setTimeout(() => {
        this.error.active = false
        this.error.text = null
        this.errorVariant = defaultErrorVariant
      }, 2000)
    }
  }

  async beforeMount() {
    this.howtoRegisterPage = await getParam("howto.register.page")
  }

  onVerify (token: string) {
    this.form.token = token
  }

  async onSubmit () {

    if (this.form.username === null || this.form.username.length === 0) {
      this.throwAuthError('Введите логин или email!')
      return
    }
    if (this.form.password === null || this.form.password.length === 0) {
      this.throwAuthError('Введите пароль!')
      return
    }

    const json = await fetcher.post('/user/signIn', fetcher.getJsonForm(this.form))

    if (json.status.code === 200) {
      emitter.emit('user.login', json.data)
      await this.$router.push({name: this.back ? this.back : 'home'})
    } else if (json.status.code === 308) {
      this.throwAuthError(`Здравствуйте! Вы ввели правильный пароль,` +
          ` в связи со включенной двухфакторной авторизацией на вашем аккаунте,` +
          ` на вашу почту были отправлены инструкции для входа в профиль`, 0, 'success')
    } else if (json.status.code === 403) {
      this.throwAuthError(`Ваш аккаунт неактивирован,` +
          ` на вашу почту <b>${json.data}</b> было отправлено письмо со ссылкой для активации,` +
          ` пожалуйста, перейдите по ней для подтверждения почтового ящика. Если письмо не приходило` +
          ` проверьте папку "спам". Если нет и там, свяжитесь с нами через форму обратной связи`, 0)
    } else if (json.status.code === 400) {
      this.throwAuthError(`Некорректный запрос! Возможно не пройдена проверка ReCaptcha!`)
    } else {
      this.throwAuthError('Неверный логин или пароль!')
    }

    // очистим форму
    this.form.username = this.form.password = null
    document.querySelectorAll("#login-form input").forEach((el: any) => el.blur())
  }
}
