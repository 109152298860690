


























import {Component, Prop, Vue} from "vue-property-decorator";
import {PageEntity} from "@/types/PageEntity";
import TextEditor from "@/components/Interface/TextEditor.vue";
import {ifNotMatch} from "@/types/Form";
@Component({
  components: {TextEditor}
})
export default class PageForm extends Vue {
  @Prop() page!: PageEntity

  onTextChange (e: any) {
    this.page.text = encodeURIComponent(e.html)
  }

  get codeValidation () {
    const val = this.page.code
    const regexp = new RegExp('^[a-zA-Z-\_0-9]+$')
    return val.trim().length == 0 || !ifNotMatch(val, regexp, 'true')
  }
}
