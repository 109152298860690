






import {Component, Prop, Vue} from "vue-property-decorator";
import FaqForm from "@/components/Admin/Faq/FaqForm.vue";
import {FaqEntity} from "@/types/FaqEntity";
import fetcher from "@/lib/fetcher";
import {uuid} from "@/lib/string";

@Component({
  components: {FaqForm}
})
export default class FaqEdit extends Vue {
  @Prop() id!: number
  entity: FaqEntity | null = null
  isFetched: boolean = false

  mounted () {
    fetcher.get(`/faq/by-id/${this.id}`).then(res => {
      res.uuid = uuid()
      this.assignEntity(res)
    }).finally(() => {this.isFetched = true})
  }

  onSubmit () {
    fetcher.post('/faq', fetcher.getJsonForm(this.entity))
        .then((f: FaqEntity) => {
          this.assignEntity(f)
          this.alertResult(String(this.entity?.uuid), true)
        })
        .catch(() => this.alertResult(String(this.entity?.uuid), false))
  }

  alertResult (uuid: string, isSuccess: boolean) {
    const resultVariant = isSuccess ? 'success' : 'danger'
    const resultText  = isSuccess ? 'Сохранено!': 'Непредвиденная ошибка!'
    const btn = document.querySelector<HTMLInputElement>(`#save-faq-${uuid}`)
    if (btn) {
      const originalClasses = btn.getAttribute('class')
      const originalText = btn.innerText
      const resultClasses = `btn btn-${resultVariant}`
      btn.setAttribute('class', resultClasses)
      btn.innerText = resultText
      setTimeout(() => {
        btn.setAttribute('class', String(originalClasses))
        btn.innerText = originalText
      }, 1500)
    }
  }

  assignEntity (e: any) {
    this.entity = Object.assign({}, this.entity, e)
  }
}
