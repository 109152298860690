















































































































import Layout from '@/components/Layout/Layout.vue'
import {Vue, Component} from 'vue-property-decorator'
import { UserProfile, ProfileMessage } from '@/types/Profile'
import Photo from '@/components/Profile/Photo.vue'
import ProfileForm from '@/components/Profile/ProfileForm.vue'
import ChangePasswordForm from '@/components/Profile/ChangePasswordForm.vue'
import {isAdmin, getStoredUser} from "@/lib/user";
import fetcher from '@/lib/fetcher'
import emitter from '@/emitter'
import {UserAvatar} from '@/types/User'
import {Crumb} from "@/types/Breadcrumbs";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {getDifference, getFormattedDateTime} from "@/lib/date";
import Feedback from "@/components/Feedback.vue";
import {NavItem} from "@/types/Sidebar";
import ProfileNav from "@/types/ProfileNav";
import HorizontalBtnNav from "@/components/Interface/HorizontalBtnNav.vue";
import {getParams} from "@/lib/params";

@Component({
  components: {HorizontalBtnNav, Feedback, Breadcrumbs, Layout, Photo, ProfileForm, ChangePasswordForm}
})
export default class Profile extends Vue {

  name: string = 'Мой профиль'
  crumbs: Crumb[] = [{title: this.name}]
  message: ProfileMessage = new ProfileMessage()
  monthTimer = {day: 0, hour: 0, min: 0, sec: 0}
  profile!: UserProfile
  welcomeLink = ''
  isAdmin: boolean = false
  toShop = {id: 1}
  toRef = {id: 1}
  horizontalItems: Array<NavItem> = ProfileNav
  multipliers: {days: number, multiplier: number} = {days: 0, multiplier: 0}
  isMultipliersFetched: boolean = false
  isAdminFetched: boolean = false
  copyBtnVariant = 'light-blue'
  copyBtnText = 'Скопируйте'
  actionMultiplePointsDescription = ""
  actionMultiplePointsTitle: string = ""
  actionMultiplePointsTimerText: string = ""

  get isNeedShowAction () {
    const registeredDate = new Date()
    registeredDate.setTime(Date.parse(this.profile.created!))
    const monthDate = new Date()
    monthDate.setDate(registeredDate.getDate() + 31)
    return this.isMultipliersFetched && !this.profile.isBuyProduct && monthDate.getTime() > new Date().getTime()
  }

  get whenRegistered () {
    return getFormattedDateTime(this.profile.created, 'dd.MM.yyyy hh:mm')
  }

  get dayCountText () { return (this.multipliers.days === 1) ? "дня" : "дней" }

  calculateTimer () {
    const registeredDate = new Date (this.profile.created!)
    const monthDate = new Date(registeredDate)
    monthDate.setDate(registeredDate.getDate() + this.multipliers.days)
    const now = new Date()
    return getDifference(monthDate, now)
  }

  getRank () {
    if (this.profile?.countInvited) {
      if (this.profile.countInvited > 0 && this.profile.countInvited < 4) return 'Эксперт моды'
      if (this.profile.countInvited > 3 && this.profile.countInvited < 10) return 'Серебренная'
      if (this.profile.countInvited > 10 && this.profile.countInvited < 21) return 'Золотая'
      if (this.profile.countInvited > 20 && this.profile.countInvited < 31) return 'Платиновая'
      if (this.profile.countInvited > 30 && this.profile.countInvited < 41) return 'Бриллиантовая'
      if (this.profile.countInvited > 40 && this.profile.countInvited >= 41) return 'Супер-звезда'
    }
    return 'Покупатель'
  }

  beforeMount () {
    const keys = [
      'multiple.points.days',
      'multiple.points.multiplier',
      'action.multiple-points.description',
      'action.multiple-points.timer-text',
      'action.multiple-points.title',
    ]
    getParams(keys).then(params => {
      this.multipliers.days = parseInt(params[keys[0]], 10)
      this.multipliers.multiplier = parseInt(params[keys[1]], 10)
      this.actionMultiplePointsDescription = params[keys[2]]
      this.actionMultiplePointsTimerText = params[keys[3]]
      this.actionMultiplePointsTitle = params[keys[4]]
      this.isMultipliersFetched = true
    })
  }

  async mounted() {
    this.horizontalItems.map(i => { if (i.to === 'admin') { i.show = isAdmin() } })
    this.isAdminFetched = true
    getStoredUser().then(async user => {
      try {
        const json = await fetcher.get('/profile', {
          headers: new Headers({ 'Authorization': 'Bearer ' + user.accessToken })
        })
        if (json.status.code === 200) {
          this.profile = Object.assign({}, this.profile, json.data)
          if (!this.profile.avatar) this.profile.avatar = new UserAvatar()
          this.profile.welcomeLink = location.protocol + '//' + location.host + '/page/welcome?refId=' + this.profile.loginId
          this.message.error = false
          this.toRef = Object.assign({}, this.toRef, {
            id: this.profile?.refUserId,
            name: this.profile?.id === 1 ? "Администратор" : this.profile?.refName || 'Имя пользователя не указано'
          })
        } if (json.status.code === 401) {
          emitter.emit('user.logout')
        } else {
          this.message.value = json.message
        }

        if (this.isNeedShowAction) {
          setInterval(() => {
            this.monthTimer = Object.assign({}, this.monthTimer, this.calculateTimer())
          }, 1000)
        }
        this.isAdmin = await isAdmin()
      } catch (e) {
        console.log(e)
        this.message.value = 'ошибка обращения к серверу'
      }
    }).catch(() => this.message.value = 'пользователь не авторизован')
  }

  copyReferral () {
    navigator.clipboard.writeText(String(this.profile.welcomeLink))
    this.copyBtnText = 'Скопировано!'
    this.copyBtnVariant = 'success'
    setTimeout(() => {
      this.copyBtnText = 'Скопируйте'
      this.copyBtnVariant = 'light-blue'
    }, 1500)
  }

  onPasswordChanged () {
    this.$bvModal.hide('password-change-modal')
  }

  onUserLogout () {
    localStorage.clear()
    this.$router.push('/')
  }
}
