











import Layout from '@/components/Layout/Layout.vue'
import {Vue, Component, Prop} from 'vue-property-decorator'
import {OAuth2Message} from '@/types/OAuth2'
import emitter from '@/emitter'
import fetcher from '@/lib/fetcher'

@Component({
  components: { Layout }
})
export default class OAuth2 extends Vue {
  @Prop() source!: string
  @Prop() back!: string

  data: OAuth2Message = new OAuth2Message()

  async fetchUserData () {
    try {
      const json = await fetcher.get(location.pathname + location.search)
      if (json.status.code === 200) {
        emitter.emit('user.login', json.data)
        await this.$router.push({name: this.back ? this.back : 'profile'})
      } else {
        this.data.message = json.message
        this.data.isAuthFailed = true
      }
    } catch (e) {
      this.data.message = 'Не удалось получить ответ от сервера'
      this.data.isAuthFailed = true
    }
  }

  mounted () { this.fetchUserData() }
}
