




import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class VK extends Vue {
  @Prop() url: string | undefined

  mounted() {
    this.$emit('video-mounted')
  }
}
