











import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import VueRecaptcha from "vue-recaptcha";

@Component({
  components: { VueRecaptcha }
})
export default class ReCaptcha extends Vue {
  @Prop() reload!: boolean

  @Watch('reload')
  onNeedReload (reload: boolean) {
    if (reload) this.onCaptchaExpired()
  }

  onVerify (token: string) {
    this.$emit('verify', token)
  }

  onCaptchaExpired () {
    (<any> this.$refs.recaptcha).reset()
  }
}
