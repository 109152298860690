


















import {Component} from "vue-property-decorator";
import GridView from "@/components/Admin/GridView.vue";
import Grid from "@/components/Interface/Grid.vue";

@Component({
  components: {Grid}
})
export default class StatByCity extends GridView {

  isNeedSort: boolean = false
  gridEndpoint: string = '/stat/by-city'
  defaultPageSize = 50

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    // noinspection NonAsciiCharacters
    return {
      'город': entity.city,
      'кол-во': entity.count,
    }
  }
}
