import { render, staticRenderFns } from "./OK.vue?vue&type=template&id=fef2d134&scoped=true&"
import script from "./OK.vue?vue&type=script&lang=ts&"
export * from "./OK.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fef2d134",
  null
  
)

export default component.exports