






























import {Component, Prop, Vue} from 'vue-property-decorator'
import Form from "@/components/Form/Form.vue";
import {Field, ifEmpty} from "@/types/Form";
import fetcher from "@/lib/fetcher";
import emitter from "@/emitter";
import {getStoredUserFromLocalStorage} from "@/lib/user";
import ReCaptcha from "@/components/Interface/ReCaptcha.vue";
import Agreement from "@/components/Interface/Agreement.vue";

@Component({
  components: {Agreement, ReCaptcha, Form: Form}
})
export default class FirstLine extends Vue {
  @Prop() to: { id: number | undefined; name: string } | undefined
  modalId = 'feedback-modal-' + new Date().getTime()
  emailSend = false
  isCaptchaVerified = false
  captchaToken = ''
  alertCaptchaUnverified = false
  formKey = 1

  fields: Array<Field> = [
    {
      name: 'feedbackEmail',
      type: 'email',
      noLabel: true,
      label: 'Ваш E-mail для обратной связи',
      hasErrors: ifEmpty
    },
    {
      name: 'subject',
      noLabel: true,
      label: 'Тема письма',
      hasErrors: (val: any) => {
        if (val.length > 255) return `слишком длинная тема письма (${val.length} символов), максимум 255`
      }
    },
    {
      name: 'body',
      type: 'textarea',
      noLabel: true,
      label: 'Текст сообщения',
      hasErrors: ifEmpty
    },
  ]

  onSubmit (fields: any) {

    fields.to = this.to?.id
    fields.captchaToken = this.captchaToken
    const from = getStoredUserFromLocalStorage()
    if (from) fields.from = from.id

    fetcher.post('/message/send', fetcher.getJsonForm(fields)).then(result => {
      if (result.status.code === 200) {
        this.emailSend = true
        setTimeout(() => {
          this.$bvModal.hide(this.modalId)
          setTimeout(() => this.emailSend = false, 200)
        }, 2500)
      } else if (result.status.code === 401) {
        emitter.emit('user.logout')
      }
    })
  }

  onVerify (token: string) {
    this.captchaToken = token
    this.isCaptchaVerified = true
  }

  onInternalError () {
    if (!this.isCaptchaVerified) {
      this.alertCaptchaUnverified = true
      setTimeout(() => this.alertCaptchaUnverified = false, 1500)
      return false
    }
  }
}
