export function setCookie(name: string, value: string, options: any = {}) {

    const opt: {[i: string]: any} = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (opt.expires instanceof Date) {
        opt.expires = opt.expires.toUTCString();
        console.log(opt)
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in opt) {
        updatedCookie += "; " + optionKey;
        let optionValue = opt[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export function deleteCookie(name: string) {
    setCookie(name, "", {
        'max-age': -1
    })
}

export function getCookie(name: string) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
