export function getSibling (elem: HTMLElement, selector: string): HTMLElement | undefined {
    let sibling = elem.nextElementSibling
    while (sibling) {
        if (sibling.matches(selector)) return <HTMLElement> sibling
        sibling = sibling.nextElementSibling
    }
}

export function getContentWidth (element: HTMLElement) {
    const styles = getComputedStyle(element)
    return element.clientWidth
        - parseFloat(styles.paddingLeft)
        - parseFloat(styles.paddingRight)
}
