











































































































































































import {Component, Vue} from 'vue-property-decorator'
import TextEditor from '@/components/Interface/TextEditor.vue'
import fetcher from "@/lib/fetcher";

type Param = { key: string, value: string, isText: boolean }

@Component({ components: { TextEditor } })
export default class Settings extends Vue {

  isSaveSuccess = false
  isSettingsFetched = false
  settings: Array<Param> = []
  welcomePointsAmount: number = 0
  multiplePointsDays: number = 0
  multiplePointsMultiplier: number = 0
  orderPointsMultiplierBuyer: number = 0
  orderPointsMultiplierFirstLine: number = 0
  orderPointsMultiplierSecondLine: number = 0
  orderPointsMultiplierThirdLine: number = 0
  orderOfferPage: string = ""
  elevenPlusOnePage: string = ""
  bonusProgramPage: string = ""
  usagePolicyPage: string = ""
  registrationWelcomeText: string = ""
  orderUnderCartText: string = ""
  orderUnderFormText: string = ""
  howtoRegisterPage: string = ""
  privacyPolicyPage: string = ""
  actionMultiplePointsDescription: string = ""
  actionMultiplePointsTitle: string = ""
  actionMultiplePointsTimerText: string = ""

  beforeMount() {
    fetcher.get('/settings').then(settings => {
      this.settings = Object.assign({}, this.settings, settings)
      this.fillSettings()
    }).finally(() => this.isSettingsFetched = true)
  }

  fillSettings () {
    this.welcomePointsAmount = this.getNumberParam('welcome.points.amount')
    this.multiplePointsDays = this.getNumberParam('multiple.points.days')
    this.multiplePointsMultiplier = this.getNumberParam('multiple.points.multiplier')
    this.orderPointsMultiplierBuyer = this.getNumberParam('order.points.multiplier.buyer')
    this.orderPointsMultiplierFirstLine = this.getNumberParam('order.points.multiplier.first-line')
    this.orderPointsMultiplierSecondLine = this.getNumberParam('order.points.multiplier.second-line')
    this.orderPointsMultiplierThirdLine = this.getNumberParam('order.points.multiplier.third-line')
    this.orderOfferPage = this.getStringParam('order.offer.page')
    this.elevenPlusOnePage = this.getStringParam('action.11-plus-1.page')
    this.bonusProgramPage = this.getStringParam('bonus.program.page')
    this.usagePolicyPage = this.getStringParam('usage.policy.page')
    this.registrationWelcomeText = this.getStringParam('registration.welcome.text')
    this.orderUnderCartText = this.getStringParam('order.under-cart.text')
    this.orderUnderFormText = this.getStringParam('order.under-form.text')
    this.howtoRegisterPage = this.getStringParam('howto.register.page')
    this.privacyPolicyPage = this.getStringParam('privacy.policy.page')
    this.actionMultiplePointsDescription = this.getStringParam('action.multiple-points.description')
    this.actionMultiplePointsTitle = this.getStringParam('action.multiple-points.title')
    this.actionMultiplePointsTimerText = this.getStringParam('action.multiple-points.timer-text')
  }

  getStringParam (key: string): string {
    let param = null
    for (let i in this.settings) {
      if (this.settings.hasOwnProperty(i) && this.settings[i].key === key) {
        param = this.settings[i].value
        break;
      }
    }
    return param ? param : ""
  }

  getNumberParam (key: string): number {
    const param = this.getStringParam(key)
    return param != null ? parseInt(param, 10) : 0
  }

  onSubmit () {
    const params = [
      { key: 'welcome.points.amount', value: this.welcomePointsAmount },
      { key: 'multiple.points.days', value: this.multiplePointsDays },
      { key: 'multiple.points.multiplier', value: this.multiplePointsMultiplier },
      { key: 'order.points.multiplier.buyer', value: this.orderPointsMultiplierBuyer },
      { key: 'order.points.multiplier.first-line', value: this.orderPointsMultiplierFirstLine },
      { key: 'order.points.multiplier.second-line', value: this.orderPointsMultiplierSecondLine },
      { key: 'order.points.multiplier.third-line', value: this.orderPointsMultiplierThirdLine },
      { key: 'order.offer.page', value: this.orderOfferPage },
      { key: 'action.11-plus-1.page', value: this.elevenPlusOnePage },
      { key: 'bonus.program.page', value: this.bonusProgramPage },
      { key: 'usage.policy.page', value: this.usagePolicyPage },
      { key: 'registration.welcome.text', value: this.registrationWelcomeText },
      { key: 'order.under-cart.text', value: this.orderUnderCartText },
      { key: 'order.under-form.text', value: this.orderUnderFormText },
      { key: 'howto.register.page', value: this.howtoRegisterPage },
      { key: 'privacy.policy.page', value: this.privacyPolicyPage },
      { key: 'action.multiple-points.description', value: this.actionMultiplePointsDescription },
      { key: 'action.multiple-points.title', value: this.actionMultiplePointsTitle },
      { key: 'action.multiple-points.timer-text', value: this.actionMultiplePointsTimerText },
    ]
    fetcher.post('/settings', fetcher.getJsonForm(params)).then(() => {
      this.isSaveSuccess = true
      setTimeout(() => this.isSaveSuccess = false, 1500)
    })
  }

  onOrderUnderFormTextChange (e: any) {
    this.orderUnderFormText = encodeURIComponent(e.html)
  }

  onOrderUnderCartTextChange (e: any) {
    this.orderUnderCartText = encodeURIComponent(e.html)
  }

  onActionMultiplePointsDescriptionChange (e: any) {
    this.actionMultiplePointsDescription = encodeURIComponent(e.html)
  }

  onActionMultiplePointsTimerTextChange(e: any) {
    this.actionMultiplePointsTimerText = encodeURIComponent(e.html)
  }
}
