














import {Component} from "vue-property-decorator";
import Grid from "@/components/Interface/Grid.vue";
import GridView from "@/components/Admin/GridView.vue";

@Component({
  components: {Grid}
})
export default class FaqList extends GridView {

  gridEndpoint: string = '/faq'

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    return {
      id: entity.id,
      question: entity.question.length > 100 ? entity.question.substr(0, 100) + '...' : entity.question,
    }
  }
}
