




















































import {Component, Prop, Vue} from 'vue-property-decorator'
import {UserProfile} from '@/types/Profile'
import Form from '@/components/Form/Form.vue'
import fetcher from '@/lib/fetcher'
import emitter from '@/emitter'
import {getFormattedDateTime} from '@/lib/date'
import {getFormattedPhone, ucFirst} from '@/lib/formatter'

@Component({
  components: {Form:Form}
})
export default class ProfileForm extends Vue {
  @Prop() profile!: UserProfile
  submitErrorText: string = ''
  isSubmitSuccess: boolean = false
  isSubmitError: boolean = false
  isRegionsFetched: boolean = false
  form = {
    email: '',
    lastName: '',
    firstName: '',
    middleName: '',
    birthdate: '',
    city: '',
    phone: '',
  }
  regionList: string[] = []
  otherCity: boolean = false

  beforeMount() {
    this.form.email = this.profile.email || ''
    this.form.lastName = this.profile.lastName || ''
    this.form.firstName = this.profile.firstName || ''
    this.form.middleName = this.profile.middleName || ''
    this.form.birthdate = this.profile.birthdate || ''
    this.form.city = this.profile.city || ''
    this.form.phone = this.profile.phone ? getFormattedPhone(this.profile.phone) : ''

    fetcher.get('/regions').then((regions: any) => {
      regions.map((region: any) => {
        this.regionList.push(region.name)
      })
    }).finally(() => this.isRegionsFetched = true)
  }

  onSubmit () {

    if (this.isSubmitError || this.isSubmitSuccess) return;

    if (this.form.email.length === 0) {
      this.submitError("email не может быть пустым")
      return;
    }
    if (this.form.firstName.length === 0) {
      this.submitError("Ваше Имя не может быть пустым")
      return;
    }
    if (this.regionList.indexOf(this.form.city) < 0) {
      this.submitError("Должен быть указан конкретный регион из выпадающего списка регионов или отмечена галочка о том что вашего региона нет в списке")
      return;
    }

    if (this.form.phone) {
      this.form.phone = this.form.phone.replace(/[^0-9]/g, '')
    }
    if (this.form.birthdate) {
      this.form.birthdate = getFormattedDateTime(this.form.birthdate, 'yyyy-MM-ddThh:mm:ssZ') || ""
    }

    fetcher.put('/profile', fetcher.getJsonForm(this.form))
        .then((json: any) => {
          if (json.status.code === 200) {
            this.profile.city = json.data.city
            this.profile.username = json.data.username
            this.profile.firstName = json.data.firstName
            this.profile.lastName = json.data.lastName
            this.profile.middleName = json.data.middleName
            this.profile.email = json.data.email
            this.profile.birthdate = getFormattedDateTime(new Date(json.data.birthdate), 'yyyy-MM-dd')
            this.profile.phone = json.data.phone
            if (json.data.avatar) {
              this.profile.avatar!.id = json.data.avatar.id
              this.profile.avatar!.created = json.data.avatar.created
              this.profile.avatar!.updated = json.data.avatar.updated
              this.profile.avatar!.name = json.data.avatar.name
              this.profile.avatar!.originalName = json.data.avatar.originalName
              this.profile.avatar!.status = json.data.avatar.status
              this.profile.avatar!.extension = json.data.avatar.extension
              this.profile.avatar!.url = json.data.avatar.url
            }
            this.isSubmitSuccess = true
            setTimeout(() => this.isSubmitSuccess = false, 1500)
          } if (json.status.code === 401) {
            emitter.emit('user.logout')
          }
        }).catch((e: any) => console.log(e))
  }

  submitError (text: string) {
    this.submitErrorText = ucFirst(text) + "!"
    this.isSubmitError = true
    setTimeout(() => {
      this.isSubmitError = false
      this.submitErrorText = ''
    }, 2000)
  }

  getFormattedPhone () {
    if (this.form.phone.length > 0) {
      this.form.phone = getFormattedPhone(this.form.phone)
    }
  }

  onOtherRegionChange () {
    if (this.otherCity) {
      this.form.city = 'Другие страны мира'
    } else {
      this.form.city = ''
    }
  }
}
