







import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/components/Layout/Layout.vue'
import {Crumb} from '@/types/Breadcrumbs'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import AdaptiveTable from "@/components/Interface/AdaptiveTable.vue";
import OrderView from "@/components/OrderDetail/OrderView.vue";
import fetcher from "@/lib/fetcher";

@Component({
  components: {OrderView, AdaptiveTable, Layout, Breadcrumbs }
})
export default class OrderDetail extends Vue {

  @Prop() orderId: string | undefined

  isOrderFetched: boolean = false
  name: string = 'Детали заказа'
  crumbs: Crumb[] = [{title: this.name}]
  order: {[index: string]: any} = {}

  beforeMount() {
    fetcher.get('/order/' + this.orderId).then(result => {
      if (result.status.code === 200)
        this.order = Object.assign({}, this.order, result.data)
    }).finally(() => this.isOrderFetched = true)
  }
}
