















import {Component} from 'vue-property-decorator'
import GridView from "@/components/Admin/GridView.vue";
import Grid from "@/components/Interface/Grid.vue";
import {ucFirst} from "@/lib/formatter";
@Component({
  components: {Grid}
})
export default class Orders extends GridView {
  gridEndpoint: string = '/order'

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    let orderTotalSum = 0
    entity?.basket?.compositions?.map((item: any) => orderTotalSum += item.price * item.count)
    return {
      id: entity.id,
      hash: entity.hash,
      status: ucFirst(entity.orderStatus.displayValue),
      sum: orderTotalSum
    }
  }
}
