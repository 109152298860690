import UserRole from './UserRole'

export class UserAvatar {
    id!: number
    created: string | null | undefined
    updated: string | null | undefined
    status: string | null | undefined
    url: string | null | undefined
    name: string | null | undefined
    originalName: string | null | undefined
    extension: string | null | undefined
}

export default class User {
    id: number | undefined
    isAuthorized: boolean | undefined
    refId: string | undefined
    username: string | undefined
    loginId: string | undefined
    firstName: string | undefined
    lastName: string | undefined
    middleName: string | undefined
    email: string | undefined
    city: string | undefined
    roles: Array<UserRole> = new Array<UserRole>()
    created: string | undefined
    balance: number | undefined
    reservedBalance: number | undefined
    phone: string | undefined
    birthdate: string | undefined
    avatar: UserAvatar | undefined

    constructor(params?: any) {
        if (params.hasOwnProperty('isAuthorized'))
            this.isAuthorized = params.isAuthorized
        if (params.hasOwnProperty('refId'))
            this.refId = params.refId
        if (params.hasOwnProperty('username'))
            this.username = params.username
        if (params.hasOwnProperty('loginId'))
            this.loginId = params.loginId
        if (params.hasOwnProperty('firstName'))
            this.firstName = params.firstName
        if (params.hasOwnProperty('lastName'))
            this.lastName = params.lastName
        if (params.hasOwnProperty('middleName'))
            this.middleName = params.middleName
        if (params.hasOwnProperty('email'))
            this.email = params.email
        if (params.hasOwnProperty('city'))
            this.city = params.city
        if (params.hasOwnProperty('roles'))
            params.roles.forEach((roleParams: any) => {
                const role = new UserRole(roleParams)
                this.roles.push(role)
            })
        if (params.hasOwnProperty('created'))
            this.created = params.created
        if (params.hasOwnProperty('balance'))
            this.balance = params.balance
        if (params.hasOwnProperty('reservedBalance'))
            this.reservedBalance = params.reservedBalance
        if (params.hasOwnProperty('phone'))
            this.phone = params.phone
        if (params.hasOwnProperty('birthdate'))
            this.birthdate = params.birthdate
        if (params.hasOwnProperty('avatar'))
            this.avatar = params.avatar
    }
}

export class StoredUser {
    id: number | undefined
    type: string | undefined
    username: string | undefined
    accessToken: string | undefined
    authorities: Array<{authority: string}> | undefined
}
