






























import {Component, Vue} from "vue-property-decorator";
import Layout from "@/components/Layout/Layout.vue";
import ReCaptcha from "@/components/Interface/ReCaptcha.vue";
import fetcher from "@/lib/fetcher";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {Crumb} from "@/types/Breadcrumbs";

@Component({
  components: {Breadcrumbs, ReCaptcha, Layout}
})
export default class ResetPassword extends Vue {

  title: string = 'Восстановление пароля'
  crumbs: Crumb[] = [{ to: 'login', title: 'Вход' }, {title: this.title}]
  email: string = ''
  token: string = ''

  isNeedCaptchaReload: boolean = false
  isCaptchaPassed: boolean = false
  showCaptchaRequiredAlert = false

  isResponseBadRequest: boolean = false
  isResponseNotFound: boolean = false
  isResponseSuccess: boolean = false

  get emailValidation () {
    return this.email.length > 0
  }

  onSubmit () {
    if (!this.isCaptchaPassed) {
      this.showCaptchaRequiredAlert = true
      setTimeout(() => this.showCaptchaRequiredAlert = false, 2000)
      return false
    }

    if (!this.emailValidation) return false

    const url = `/user/reset-password?token=${this.token}&email=${this.email}`
    fetcher.get(url).then(result => {
      this.isNeedCaptchaReload = true
      if (result.status.code === 200) {
        this.isResponseSuccess = true
      } else if (result.status.code === 400) {
        this.isResponseBadRequest = true
      } else if (result.status.code === 404) {
        this.isResponseNotFound = true
        setTimeout(() => this.isResponseNotFound = false, 2000)
      }
      this.isCaptchaPassed = false
      setTimeout(() => this.isNeedCaptchaReload = false, 500)
    })
  }

  onVerify (token: string) {
    this.token = token
    this.isCaptchaPassed = true
  }
}
