












































import {Component} from 'vue-property-decorator'
import Grid from "@/components/Interface/Grid.vue";
import GridView from "@/components/Admin/GridView.vue";

@Component({
  components: {Grid}
})
export default class Users extends GridView {

  gridEndpoint = '/admin/user'
  otherQuery = ''
  search: {
    email?: string | null
    firstName?: string | null
    middleName?: string | null
    lastName?: string | null
    loginId?: string | null
    birthdateStart?: string | null
    birthdateEnd?: string | null
  } = {}
  showToggleCollapseSearch: boolean = true

  mounted () {
    const nav = document.querySelector<HTMLElement>('.horizontal-btn-nav')
    const last = nav?.querySelector<HTMLElement>('li:last-child')
    last?.classList.add('active')
  }

  fetchMapping(e: { [p: string]: any }): { [p: string]: any } {
    return {
      id: e.id,
      firstName: e.firstName,
      login: e.username,
      email: e.email,
      'Login ID': e.loginId,
    }
  }

  onSearch () {
    this.addExistsSearch()
    this.fetchPage()
  }

  onSearchReset () {
    this.search.email = null
    this.search.firstName = null
    this.search.middleName = null
    this.search.lastName = null
    this.search.loginId = null
    this.search.birthdateStart = null
    this.search.birthdateEnd = null
    this.onSearch()
  }

  onToggleCollapseSearch () {
    this.showToggleCollapseSearch = !this.showToggleCollapseSearch
  }

  addExistsSearch () {
    this.otherQuery = ''
    if (this.search.email) this.otherQuery += `&firstName=${this.search.email}`
    if (this.search.firstName) this.otherQuery += `&firstName=${this.search.firstName}`
    if (this.search.middleName) this.otherQuery += `&middleName=${this.search.middleName}`
    if (this.search.lastName) this.otherQuery += `&lastName=${this.search.lastName}`
    if (this.search.loginId) this.otherQuery += `&loginId=${this.search.loginId}`
    if (this.search.birthdateStart) this.otherQuery += `&birthdateStart=${this.search.birthdateStart + ' 00:00:00.0'}`
    if (this.search.birthdateEnd) this.otherQuery += `&birthdateEnd=${this.search.birthdateEnd + ' 00:00:00.0'}`
  }
}
