































import {Component, Vue} from 'vue-property-decorator'
import {getParam} from "@/lib/params";
import TextEditor from "@/components/Interface/TextEditor.vue";
import fetcher from "@/lib/fetcher";
@Component({
  components: {TextEditor}
})
export default class HomePage extends Vue {
  seoTitle: string = ''
  seoContent: string = ''
  isSeoFetched: boolean = false
  isSaveSuccess: boolean = false
  isSaveError: boolean = false

  async beforeMount() {
    this.seoTitle = await getParam('home-page.seo-title')
    this.seoContent = await getParam('home-page.seo-text')
    this.isSeoFetched = true
  }

  onSeoContentChange (e: any) {
    this.seoContent = encodeURIComponent(e.html)
  }

  onSaveSettings () {
    const params = [
      {key: 'home-page.seo-title', value: this.seoTitle},
      {key: 'home-page.seo-text', value: this.seoContent},
    ]
    fetcher.post('/settings', fetcher.getJsonForm(params)).then(() => {
      this.isSaveSuccess = true
      setTimeout(() => this.isSaveSuccess = false, 1500)
    }).catch(() => {
      this.isSaveError = true
      setTimeout(() => this.isSaveError = false, 1500)
    })
  }
}
