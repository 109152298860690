export interface Field {
    id?: string,
    name: string,
    type?: string,
    label: string,
    validFeedback?: string,
    hasErrors?: any,
    placeholder?: string,
    valid?: boolean
    value?: any
    size?: number
    noLabel?: boolean
    disabled?: boolean
    multiple?: boolean
    afterLabelTemplate?: string
}

export function ifEmpty (val: string): string | undefined {
    if (!val.length) return 'поле не может быть пустым'
}

export function minLength (val: string, length: number): string | undefined {
    if (val.length < length) return `поле не может быть короче ${length} символов`
}

export function maxLength (val: string, length: number): string | undefined {
    if (val.length > length) return `поле не может быть длиннее ${length} символов`
}

export function ifNotMatch (val: string, regexp: RegExp, errorText: string) {
    if (!val.match(regexp)) return errorText
}
