











































































































import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/components/Layout/Layout.vue'
import Form from '@/components/Form/Form.vue'
import fetcher from '@/lib/fetcher'
import {getFormattedPhone, ucFirst} from '@/lib/formatter'
import {getFormattedDateTime} from "@/lib/date";
import { getStoredUser } from '@/lib/user'
import {getParam} from "@/lib/params";
import ReCaptcha from "@/components/Interface/ReCaptcha.vue";
import Agreement from "@/components/Interface/Agreement.vue";

@Component({
  components: {Agreement, ReCaptcha, Layout, Form: Form}
})
export default class Registration extends Vue {
  @Prop() refId!: string
  hostOrigin: string = location.origin
  avatarUrl: string = ''
  name: string = ''
  usagePolicyPage: string = ''
  registrationErrorMessage: string = ''
  registrationWelcomeText: string = ''
  isRegistrationWelcomeTextFetched: boolean = false
  isUsagePolicyLinkFetched: boolean = false
  registrationSuccess: boolean = false
  registrationError: boolean = false
  isRegionsFetched: boolean = false
  isSubmitError: boolean = false
  submitErrorText = ''
  regionList: string[] = []

  form = {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    city: '',
    otherCity: false,
    phone: '',
    password: '',
    repeatPassword: '',
    agree: false,
    token: '',
    refId: '',
  }

  onSubmit () {

    if (this.isSubmitError) return;

    if (this.form.email.length === 0) {
      this.submitError("email не может быть пустым")
      return;
    }
    if (this.form.firstName.length === 0) {
      this.submitError("Ваше Имя не может быть пустым")
      return;
    }
    if (this.regionList.indexOf(this.form.city) < 0) {
      this.submitError("Должен быть указан конкретный регион из выпадающего списка регионов или отмечена галочка о том что вашего региона нет в списке")
      return;
    }
    if (this.form.password.length === 0) {
      this.submitError("Пароль не может быть пустым")
      return;
    }
    if (this.form.password.length < 6) {
      this.submitError("Пароль не может быть короче 6 символов")
      return;
    }
    if (this.form.repeatPassword.length === 0) {
      this.submitError("Поле с повторением пароля не может быть пустым")
      return;
    }
    if (this.form.password !== this.form.repeatPassword) {
      this.submitError("Пароли должны совпадать")
      return;
    }
    if (!this.form.agree) {
      this.submitError("Для регистрации необходимо принять условия использования сайта")
      return;
    }
    if (!this.form.token) {
      this.submitError("Для регистрации необходимо пройти проверку ReCaptcha")
      return;
    }

    const fields = {
      email: this.form.email,
      firstName: this.form.firstName,
      lastName: this.form.lastName,
      middleName: this.form.middleName,
      city: this.form.city,
      otherCity: this.form.otherCity,
      phone: this.form.phone,
      password: this.form.password,
      token: this.form.token,
      refId: this.refId,
    }

    if (fields.phone) {
      fields.phone = fields.phone.replace(/[^0-9]/g, '')
    }

    fetcher.post('/user/signUp', fetcher.getJsonForm(fields)).then((json: any) => {
      if (json.status.code === 200) {
        this.registrationSuccess = true
      } else {
        this.registrationError = true
        this.registrationErrorMessage = json.data
        setTimeout(() => {
          this.registrationError = false
        }, 3000)
      }
    })
  }

  submitError (text: string) {
    this.submitErrorText = ucFirst(text) + "!"
    this.isSubmitError = true
    setTimeout(() => {
      this.isSubmitError = false
      this.submitErrorText = ''
    }, 2000)
  }

  onVerify (token: string) {
    this.form.token = token
  }

  onOtherRegionChange () {
    if (this.form.otherCity) {
      this.form.city = 'Другие страны мира'
    } else {
      this.form.city = ''
    }
  }

  async checkReferral () {
    try {
      const json = await fetcher.get('/user/check-referral-id?refId=' + this.refId)
      if (json.status.code === 200) {
        if (json.data.avatarUrl != null) {
          this.avatarUrl = fetcher.hostName + json.data.avatarUrl.trim()
        }
        this.name = json.data.name.trim()
      } else {
        this.registrationError = true
        this.registrationErrorMessage = json.data
      }
    } catch (e) {
      this.registrationError = true
      this.registrationErrorMessage = 'сервер не отвечает'
    }
  }

  mounted() {

    getStoredUser().then(() => this.$router.push({name: 'home'})).catch(() => {})

    this.checkReferral()

    getParam('registration.welcome.text').then(text => {
      this.registrationWelcomeText = text
      this.isRegistrationWelcomeTextFetched = true
    })
    getParam('usage.policy.page').then(text => {
      this.usagePolicyPage = text
      this.isUsagePolicyLinkFetched = true
    })

    fetcher.get('/regions').then((regions: any) => {
      regions.map((region: any) => {
        this.regionList.push(region.name)
      })
    }).finally(() => this.isRegionsFetched = true)
  }

  getFormattedPhone () {
    if (this.form.phone.length > 0) {
      this.form.phone = getFormattedPhone(this.form.phone)
    }
  }
}
