































import {Component, Prop, Vue} from "vue-property-decorator";
import {SlideEntity} from "@/types/SlideEntity";
import ImageLoader from "@/components/Interface/ImageLoader.vue";
import {SelectFormElement} from "@/types/SelectFormElement";

@Component({
  components: {ImageLoader}
})
export default class SlideForm extends Vue {

  @Prop() slide!: SlideEntity
  @Prop() videoTypes!: Array<SelectFormElement>
  showVideoForm = this.slide.isVideo

  onSlideSubmit () {
    this.$emit('submit', this.slide)
  }

  onSlideImageChange (file: File | undefined) {
    this.slide.backgroundFile = file
  }

  onSlideDelete () {
    this.$emit('delete', this.slide)
  }

  onIsVideoChange () {
    this.showVideoForm = !this.showVideoForm
    this.slide.isVideo = this.showVideoForm
  }
}
