





















import fetcher from '@/lib/fetcher'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {UserAvatar} from '@/types/User'

@Component
export default class Photo extends Vue {

  @Prop() endpoint!: string
  @Prop() avatar!: UserAvatar
  @Prop() hidePhotoDescription2!: boolean
  localAvatar: UserAvatar | null = this.avatar == null ? null : this.avatar
  backendHost: string = fetcher.hostName
  photoBG: string = this.localAvatar?.id ? "background: url('" + this.backendHost + this.localAvatar.url + "?v=" + new Date().getTime() + "') center no-repeat, #fff;" : ''

  onClickPhoto() {
    const file = document.querySelector<HTMLInputElement>('.photo-container input[type="file"]')
    if (file) file.click()
  }

  async onChangePhoto() {
    const storedUser = JSON.parse(localStorage.getItem('user')!)
    const photo = document.querySelector<HTMLElement>('.photo-container .photo')
    const photoLoader = document.querySelector<HTMLElement>('.photo-container .photo-loader')
    const fileInput = document.querySelector<HTMLInputElement>('.photo-container input[type="file"]')
    if (photoLoader) {
        photoLoader.classList.remove('d-none')
    }
    if (fileInput) {
      const picture = <File> fileInput.files?.item(0)
      const data = new FormData
      data.append('file', picture)
      if (picture) {
        fetcher.post(this.endpoint != null ? this.endpoint : '/profile/update-avatar', {
          headers: new Headers({ 'Authorization': 'Bearer ' + storedUser.accessToken }),
          body: data,
        }).then(json => {
          if (json.status.code === 200) {
            if (this.localAvatar == null) this.localAvatar = Object.assign({}, this.localAvatar)
            this.localAvatar.id = json.data.id
            this.localAvatar.created = json.data.created
            this.localAvatar.updated = json.data.updated
            this.localAvatar.name = json.data.name
            this.localAvatar.originalName = json.data.originalName
            this.localAvatar.status = json.data.status
            this.localAvatar.extension = json.data.extension
            this.localAvatar.url = json.data.url

            if (photo) {
              this.photoBG = "background: url('" + fetcher.hostName + json.data.url + '?v=' + new Date().getTime() + "') center no-repeat, #fff;"
              if (photoLoader) photoLoader.classList.add('d-none')
              const container = document.querySelector<HTMLElement>('.photo-container')
              if (container) {
                container.style.border = '1px solid #4fd44f'
                setTimeout(() => {
                  container.style.border = ''
                }, 1000)
              }
            }
          }
        })
      }
    }
  }
}
