






























import {Component, Prop, Vue} from "vue-property-decorator";
import UserForm from "@/components/Admin/User/UserForm.vue";
import fetcher from "@/lib/fetcher";
import {getArrayObjectsColumn} from "@/lib/array";
import Form from "@/components/Form/Form.vue";
import {Field} from "@/types/Form";
import Photo from "@/components/Profile/Photo.vue";
import User from "@/types/User";
import {dateFromInstant} from "@/lib/date";
@Component({
  components: {Photo, Form: Form, UserForm}
})
export default class EditUser extends Vue {
  @Prop() id!: number
  values: {[index: string]: any} = {}
  isUserFetched: boolean = false
  isBalanceFetched: boolean = false
  isUserUpdated: boolean = false
  isUserUpdatedError: boolean = false
  user: User | null = null

  balanceFields: Array<Field> = [
    {
      name: 'balance',
      label: 'Количество баллов',
      type: 'number',
      value: 0
    }
  ]

  beforeMount() {
    fetcher.get(`/admin/user/${this.id}`).then(result => {
      const values = {
        username: result.username,
        refId: result.refId,
        lastName: result.lastName,
        firstName: result.firstName,
        middleName: result.middleName,
        email: result.email,
        city: result.city,
        phone: result.phone,
        birthdate: dateFromInstant(result.birthdate),
        roles: getArrayObjectsColumn(result.roles, 'id'),
        status: result.status,
      }
      this.user = Object.assign({}, this.user, result)
      this.values = Object.assign({}, this.values, values)
    }).finally(() => this.isUserFetched = true)

    fetcher.get(`/admin/user/balance/${this.id}`).then(result => {
      this.balanceFields[0].value = result
    }).finally(() => this.isBalanceFetched = true)
  }

  mounted () {
    const nav = document.querySelector<HTMLElement>('.horizontal-btn-nav')
    const last = nav?.querySelector<HTMLElement>('li:last-child')
    last?.classList.add('active')
  }

  onSubmitBalance (fields: {[index: string]: string}) {
    fetcher.put(`/admin/user/balance/${this.id}`, fetcher.getJsonForm(fields)).then(() => {
      this.isUserUpdated = true
      setTimeout(() => this.isUserUpdated = false, 2000)
    }).catch(() => {
      this.isUserUpdatedError = true
      setTimeout(() => this.isUserUpdatedError = false, 2000)
    })
  }

  onSubmit(fields: {[index: string]: any}) {
    if (fields.password != null && fields.password.length === 0) fields.password = null
    fields.id = this.id
    fetcher.put('/admin/user', fetcher.getJsonForm(fields)).then(() => {
      this.isUserUpdated = true
      setTimeout(() => this.isUserUpdated = false, 2000)
    }).catch(() => {
      this.isUserUpdatedError = true
      setTimeout(() => this.isUserUpdatedError = false, 2000)
    })
  }
}
