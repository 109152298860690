





























































import {Component} from "vue-property-decorator";
import GridView from "../GridView.vue";
import Grid from "@/components/Interface/Grid.vue";
import TextEditor from "@/components/Interface/TextEditor.vue";
import fetcher from "@/lib/fetcher";
@Component({
  components: {TextEditor, Grid}
})
export default class Mailing extends GridView {

  subject = ''
  text = ''
  gridEndpoint = '/admin/user'
  otherQuery = ''
  showToggleCollapseSearch: boolean = true
  isResponseSuccess: boolean = false

  search: {
    email?: string | null
    firstName?: string | null
    middleName?: string | null
    lastName?: string | null
    loginId?: string | null
    birthdateStart?: string | null
    birthdateEnd?: string | null
  } = {}

  onSendEmail () {
    if (!confirm("Вы уверены что хотите отправить письмо выбранному списку контактов?")) return
    const fields = {
      filter: this.search,
      subject: this.subject,
      text: this.text
    }
    fetcher.post('/admin/mailing', fetcher.getJsonForm(fields)).then(() => {
      this.onSearchReset()
      this.subject = ''
      this.text = ''
      this.isResponseSuccess = true
    })
  }

  onOneMoreMail () {
    this.isResponseSuccess = false
  }

  fetchMapping(e: { [p: string]: any }): { [p: string]: any } {
    return {
      id: e.id,
      firstName: e.firstName,
      lastName: e.lastName,
      login: e.username,
      email: e.email,
      'Login ID': e.loginId,
    }
  }

  onSearch () {
    this.addExistsSearch()
    this.fetchPage()
  }

  onSearchReset () {
    this.search.email = null
    this.search.firstName = null
    this.search.middleName = null
    this.search.lastName = null
    this.search.loginId = null
    this.search.birthdateStart = null
    this.search.birthdateEnd = null
    this.onSearch()
  }

  onToggleCollapseSearch () {
    this.showToggleCollapseSearch = !this.showToggleCollapseSearch
  }

  addExistsSearch () {
    this.otherQuery = ''
    if (this.search.email) this.otherQuery += `&email=${this.search.email}`
    if (this.search.firstName) this.otherQuery += `&firstName=${this.search.firstName}`
    if (this.search.middleName) this.otherQuery += `&middleName=${this.search.middleName}`
    if (this.search.lastName) this.otherQuery += `&lastName=${this.search.lastName}`
    if (this.search.loginId) this.otherQuery += `&loginId=${this.search.loginId}`
    if (this.search.birthdateStart) this.otherQuery += `&birthdateStart=${this.getDateStamp(this.search.birthdateStart)}`
    if (this.search.birthdateEnd) this.otherQuery += `&birthdateEnd=${this.getDateStamp(this.search.birthdateEnd)}`
  }

  getDateStamp (date: string){
    return date + ' 00:00:00.0'
  }

  onTextChange (e: any) {
    this.text = encodeURIComponent(e.html)
  }
}
