













import {Component, Prop, Vue} from "vue-property-decorator";
import PageForm from "@/components/Admin/Page/PageForm.vue";
import {PageEntity} from "@/types/PageEntity";
import fetcher from "@/lib/fetcher";
@Component({
  components: {PageForm}
})
export default class PageEdit extends Vue {
  @Prop() id!: number
  page!: PageEntity
  isPageFetched: boolean = false
  isPageSaveSuccess: boolean = false
  isPageSaveError: boolean = false

  beforeMount() {
    fetcher.get(`/page/by-id/${this.id}`)
      .then((page: PageEntity) => this.page = Object.assign({}, this.page, page))
      .finally(() => this.isPageFetched = true)
  }

  onPageSave (page: PageEntity) {
    fetcher.post('/page', fetcher.getJsonForm(page))
        .then((page: PageEntity) => {
          this.page = Object.assign({}, this.page, page)
          this.isPageSaveSuccess = true
          setTimeout(() => this.isPageSaveSuccess = false, 1500)
        }).catch(() => {
          this.isPageSaveError = true
          setTimeout(() => this.isPageSaveError = false, 1500)
        })
  }
}
