




























import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class PicturesViewer extends Vue {

  @Prop() images!: string[]
  resizeId = 0

  mounted () {
    this.resizeId = setInterval(() => {PicturesViewer.handleResize()}, 400)
  }

  beforeDestroy () {
    clearInterval(this.resizeId)
  }

  private static handleResize() {
    const images = document.querySelectorAll<HTMLElement>('.img-list-image-container')
    images.forEach( img => img.style.height = `${img.offsetWidth}px`)

    const currentImage = document.querySelector<HTMLElement>('.current-image')
    if (currentImage) currentImage.style.height = `${currentImage.offsetWidth}px`

    const previewContainer = document.querySelector<HTMLElement>('#preview-container')
    if (currentImage && previewContainer) previewContainer.style.height = currentImage.style.height

    const arrowUp = document.querySelector<HTMLElement>('.slide.arrow-up')
    const arrowDown = document.querySelector<HTMLElement>('.slide.arrow-down')
    const listPreviewContainer = document.querySelector<HTMLElement>('.img-list-container')

    if (previewContainer && listPreviewContainer && arrowUp && arrowDown) {

      const arrowUpHeight = PicturesViewer.getTotalHeight(arrowUp, 'marginBottom')
      const arrowDownHeight = PicturesViewer.getTotalHeight(arrowDown, 'marginTop')

      //высота родительского блока - (высота стрелки вверх + высота стрелки вниз)
      listPreviewContainer.style.height = `${previewContainer.offsetHeight - (arrowDownHeight + arrowUpHeight)}px`
    }
  }

  moveToTheTop (event: MouseEvent) {

    document.querySelectorAll<HTMLElement>('.img-list-image-container')
        .forEach(img => img.classList.remove('active'))

    const element = <HTMLElement> event.target
    const parent = element.parentElement
    const list = document.querySelector<HTMLElement>('.img-list')
    const currentImage = document.querySelector<HTMLElement>('.current-image img')

    if (element && parent && list && currentImage) {

      const elementSrc = element.getAttribute('src') || ''
      const elementIndex = this.getIndex(element)
      const totalPercentOffset = 18 * elementIndex + ((18 * elementIndex) / 2)

      list.style.top = `-${totalPercentOffset > 110 ? 110 : totalPercentOffset}%`
      parent.classList.add('active')
      currentImage.setAttribute('src', elementSrc)
    }
  }

  clickOnCurrentImage () {
    const currentImage = document.querySelector<HTMLElement>('.current-image img')
    if (currentImage) {
      const currentIndex = this.getIndex(currentImage)
      const images = document.querySelectorAll('.img-list-image')
      const lastIndex = this.getImagesCount() - 1
      const nextIndex = (currentIndex + 1) > lastIndex ? 0 : (currentIndex + 1)
      const nextImage = <HTMLElement> images[nextIndex]
      nextImage.click()
    }
  }

  onClickUp () {
    const list = document.querySelector<HTMLElement>('.img-list')
    const firstElement = document.querySelector<HTMLElement>('.img-list-image')
    if (list && firstElement) {
      const currentPercentOffset = Math.abs(parseInt(list.style.top, 10)) || 0
      const listHeight = PicturesViewer.getTotalHeight(list)
      const firstElementHeight = PicturesViewer.getTotalHeight(firstElement)
      const addedPercentOffset = 100 / (listHeight / (firstElementHeight))
      const totalPercentOffset = currentPercentOffset + addedPercentOffset
      list.style.top = `-${totalPercentOffset > 110 ? 110 : totalPercentOffset}%`
    }
  }

  onClickDown () {
    const list = document.querySelector<HTMLElement>('.img-list')
    const firstElement = document.querySelector<HTMLElement>('.img-list-image')
    if (list && firstElement) {
      const currentPercentOffset = Math.abs(parseInt(list.style.top, 10)) || 0
      const listHeight = PicturesViewer.getTotalHeight(list)
      const firstElementHeight = PicturesViewer.getTotalHeight(firstElement)
      const addedPercentOffset = 100 / (listHeight / (firstElementHeight))
      const totalPercentOffset = currentPercentOffset - addedPercentOffset
      list.style.top = `-${totalPercentOffset < 0 ? 0 : totalPercentOffset}%`
    }
  }

  private getIndex (el: HTMLElement) {
    const src = el.getAttribute('src')
    let target: string = ''
    for (let key in this.images) { if (this.images[key] === src) { target = key; break; } }
    return parseInt(target, 10)
  }

  private getImagesCount () {
    let counter = 0
    for (let i in this.images) counter += 1
    return counter
  }

  private static getTotalHeight (el: HTMLElement, ...margins: any) {
    const styles: CSSStyleDeclaration = window.getComputedStyle(el)
    let height = el.offsetHeight
    margins.forEach((margin: any) => height += parseInt(styles[margin], 10))
    return height
  }
}
