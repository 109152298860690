










import {Component, Vue} from "vue-property-decorator";
import PageForm from "@/components/Admin/Page/PageForm.vue";
import {PageEntity} from "@/types/PageEntity";
import fetcher from "@/lib/fetcher";
@Component({
  components: {PageForm}
})
export default class PageCreate extends Vue {

  isPageCreateError: boolean = false

  page: PageEntity = {
    id: null,
    title: '',
    header: '',
    code: '',
    text: '',
  }

  onPageSave (page: PageEntity) {
    fetcher.post('/page', fetcher.getJsonForm(page))
        .then((page: PageEntity) => this.$router.push({name: 'edit-page', params: {id: String(page.id)}}))
        .catch(() => this.isPageCreateError = true)
  }
}
