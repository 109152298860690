












import {Vue, Component} from 'vue-property-decorator'
import Layout from '@/components/Layout/Layout.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import {Crumb} from "@/types/Breadcrumbs";

@Component({
  components: {Breadcrumbs, Layout}
})
export default class NotFound extends Vue {
  name: string = '#404 страница не найдена'
  crumbs: Crumb[] = [{title: this.name}]
}
