import User from '@/types/User'

export class ProfileMessage {
    error: boolean = true
    value: string = 'подождите...'
}

export class UserProfile extends User {
    welcomeLink: string | undefined
    isBuyProduct: boolean | undefined
    refUserId: number | undefined
    countInvited: number | undefined
    refName: string | undefined

    constructor(params: any) {
        super(params);
        if (params.hasOwnProperty('welcomeLink'))
            this.welcomeLink = params.welcomeLink
        if (params.hasOwnProperty('isBuyProduct'))
            this.isBuyProduct = params.isBuyProduct
        if (params.hasOwnProperty('refUserId'))
            this.refUserId = params.refUserId
        if (params.hasOwnProperty('refName'))
            this.refName = params.refName
        if (params.hasOwnProperty('countInvited'))
            this.countInvited = params.countInvited
    }
}
