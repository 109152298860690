











import Sidebar from '@/components/Interface/Sidebar.vue'
import {Component} from 'vue-property-decorator'
import {NavItem} from "@/types/Sidebar";

@Component
export default class HorizontalBtnNav extends Sidebar {

  currentClassName(url: string): string {
    const classes = []
    if (this.isCurrentPath(url)) classes.push('active')
    return classes.join(' ')
  }

  isVisible(item: NavItem | null | undefined): boolean {
    return (item?.show != null && item.show) || item?.show == null
  }
}
