








import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {quillEditor} from 'vue-quill-editor'

@Component({ components: { quillEditor } })
export default class TextEditor extends Vue {
  @Prop() content!: string
  localContent = decodeURIComponent(this.content)
  quill: {[index: string]: any} = {}

  beforeMount() {
    document.addEventListener('click', (e: any) => {
      if (e.target.matches('.ql-editor .ql-image')) {
        const img = e.target
        const parent = img.parentElement
        parent.appendChild(this.createImageHelper(img))
      } else if (e.target.matches('.ql-editor .ql-image-helper')) {
      } else if (e.target.matches('.ql-editor .ql-image-helper-input')) {
      } else if (e.target.matches('.ql-editor .ql-image-helper-ok')) {
        const input = e.target.parentElement.querySelector('.ql-editor .ql-image-helper-input')
        const wrapper = e.target.closest('.ql-editor .ql-image-wrapper')
        const image = wrapper.querySelector('.ql-editor .ql-image')
        if (input.value.trim()) {
          image.style.width = input.value.trim()
        }
        e.target.parentElement.remove()
        this.quill.emitter.emit('text-change')
      } else if (e.target.matches('.ql-editor .ql-image-helper-auto')) {
        const wrapper = e.target.closest('.ql-editor .ql-image-wrapper')
        const image = wrapper.querySelector('.ql-editor .ql-image')
        image.style.width = ''
        e.target.parentElement.remove()
        this.quill.emitter.emit('text-change')
      } else {
        document.querySelectorAll('.ql-editor .ql-image-helper').forEach (el => el.remove())
      }
    })
  }

  createImageHelper (img: HTMLElement): HTMLElement {

    const helper: HTMLElement = document.createElement('DIV')
    helper.className = 'ql-image-helper'

    const input: HTMLInputElement = <HTMLInputElement> document.createElement('INPUT')
    input.value = img.style.width
    input.placeholder = '100%/px/rem/etc'
    input.className = 'ql-image-helper-input'

    const ok: HTMLButtonElement = <HTMLButtonElement> document.createElement('BUTTON')
    ok.textContent = 'OK'
    ok.className = 'ql-image-helper-ok'

    const auto: HTMLButtonElement = <HTMLButtonElement> document.createElement('BUTTON')
    auto.textContent = 'AUTO'
    auto.className = 'ql-image-helper-auto'

    helper.appendChild(input)
    helper.appendChild(ok)
    helper.appendChild(auto)

    return helper
  }

  onEditorReady (quill: any) {
    this.quill = quill
  }

  onEditorChange(event: any) {
    this.$emit('change', event)
  }
}
