














import {Component, Vue} from "vue-property-decorator";
import fetcher from "@/lib/fetcher";
import {BannerEntity} from "@/types/BannerEntity";
import {uuid} from "@/lib/string";
import BannerForm from "@/components/Admin/HomePage/SmallBanners/BannerForm.vue";
import {sortByNumericField} from "@/lib/sort";
@Component({
  components: {BannerForm}
})
export default class SmallBanners extends Vue {

  banners: Array<BannerEntity> = []
  isBannersFetched: boolean = false

  beforeMount() {
    fetcher.get('/small-banners/full').then((banners: Array<BannerEntity>) => {
      sortByNumericField(banners, 'id')?.map((b: BannerEntity) => {
        b.uuid = uuid()
        if (b.icon) {
          b.icon.uuid = uuid()
        } else {
          b.icon = { id: null, uuid: uuid(), url: '', name: '', originalName: '', extension: '' }
        }
        this.banners.push(b)
      })
    }).finally(() => this.isBannersFetched = true)
  }

  onBannerChange (banner: BannerEntity) {
    const form = new FormData()
    const escapeFields = ['icon']
    for (let field in banner) {
      // @ts-ignore
      if (banner.hasOwnProperty(field) && escapeFields.indexOf(field) < 0 && banner[field] != null) {
        // @ts-ignore
        form.append(field, String(banner[field]))
      }
    }
    if (banner.iconFile) {
      form.append('icon.file', banner.iconFile)
    }
    if (banner.icon?.id) {
      form.append('icon.id', String(parseInt(String(banner.icon.id), 10)))
    }
    console.log(banner, form)
    fetcher.put(`/small-banners/${banner.id}`, { body: form })
      .then(b => {
        banner.icon = Object.assign({}, banner.icon, b.icon)
        this.alertResult(String(banner.uuid), true)
      })
      .catch(() => this.alertResult(String(banner.uuid), false))
  }

  alertResult (uuid: string, isSuccess: boolean) {
    const resultVariant = isSuccess ? 'success' : 'danger'
    const resultText  = isSuccess ? 'Сохранено!': 'Непредвиденная ошибка!'
    const btn = document.querySelector<HTMLInputElement>(`#save-banner-${uuid}`)
    if (btn) {
      const originalClasses = btn.getAttribute('class')
      const originalText = btn.innerText
      const resultClasses = `btn btn-${resultVariant}`
      btn.setAttribute('class', resultClasses)
      btn.innerText = resultText
      setTimeout(() => {
        btn.setAttribute('class', String(originalClasses))
        btn.innerText = originalText
      }, 1500)
    }
  }
}
