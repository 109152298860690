





































import {Component, Prop, Vue} from 'vue-property-decorator'
import {ucFirst as ucf} from '@/lib/formatter'
import Counter from '@/components/Interface/Counter.vue'
import {ProductType} from '@/types/Product/ProductType'
import {getParam} from "@/lib/params";

@Component({
  components: {Counter}
})
export default class ProductCard extends Vue {
  elevenPlusOnePage: string | null = null
  isElevenPlusOnePageFetched: boolean = false
  @Prop() product!: ProductType

  count: number = 1

  get toProduct () {
    return { name: 'order', query: { cart: JSON.stringify([ {id: 1, count: this.count } ]) }}
  }

  get productTitle () {
    return this.product?.title
  }

  get firstParam () {
    return this.product?.params![0]
  }

  get secondParam () {
    return this.product?.params![1]
  }

  get allParams () {
    return this.product?.params
  }

  get productPrice () {
    return this.product?.price
  }

  get oldPrice () {
    return this.product?.oldPrice
  }

  onCountChange(count: number) {
    this.count = count
  }

  onclickMore () {
    this.$bvModal.show('all-props')
  }

  ucFirst (str: string) { return ucf(str) }

  beforeMount() {
    getParam('action.11-plus-1.page')
        .then(param => this.elevenPlusOnePage = param)
        .finally(() => this.isElevenPlusOnePageFetched = true)
  }

  mounted() {
    setTimeout(() => {ProductCard.handleResize()}, 200)
    window.addEventListener('resize', ProductCard.handleResize)
  }

  private static handleResize() {
    const body = document.querySelector<HTMLElement>('body')
    const card = document.querySelector<HTMLElement>('.product-card')
    const currentImage = document.querySelector<HTMLElement>('.current-image')
    if (body && card && currentImage) {
      if (body.offsetWidth > 767) {
        card.style.minHeight = `${currentImage.offsetWidth}px`
      } else if (body.offsetWidth > 400) {
        card.style.minHeight = '350px'
      } else {
        card.style.minHeight = '300px'
      }
    }
  }
}
