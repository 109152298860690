






import {Component, Prop, Vue} from "vue-property-decorator";
import {getParam} from "@/lib/params";

@Component
export default class Agreement extends Vue {

  @Prop() usage!: string
  @Prop() privacy!: string

  usagePolicyPage: string = ''
  privacyPolicyPage: string = ''

  isUsagePolicyPageFetched: boolean = false
  isPrivacyPolicyPageFetched: boolean = false

  beforeMount() {
    if (this.usage) {
      this.usagePolicyPage = this.usage
      this.isUsagePolicyPageFetched = true
    } else {
      getParam('usage.policy.page')
          .then(text => this.usagePolicyPage = text)
          .finally(() => this.isUsagePolicyPageFetched = true)
    }

    if (this.privacy) {
      this.privacyPolicyPage = this.privacy
      this.isPrivacyPolicyPageFetched = true
    } else {
      getParam('privacy.policy.page')
          .then(text => this.privacyPolicyPage = text)
          .finally(() => this.isPrivacyPolicyPageFetched = true)
    }
  }
}
