import {ProductPicture} from '@/types/Product/ProductPicture'
import {VideoType} from '@/types/VideoType'
import {ProductParam} from "@/types/Product/ProductParam";

export class ProductType {

    id: number | undefined
    code: string | undefined
    title: string | undefined
    description: string | undefined
    price: number | undefined
    oldPrice: number | undefined
    params: Array<ProductParam> | undefined
    mainPicture: ProductPicture | undefined
    pictures: ProductPicture[] | undefined
    videoType: VideoType | undefined
    videoUrl: string | undefined

    constructor(params: any) {
        Object.assign(this, params)
    }
}
