import {isAdmin} from "@/lib/user";

export default [
    {
        title: 'Мой профиль',
        to: '',
    },
    {
        title: 'Статистика',
        to: 'stat',
    },
    {
        title: 'Администрирование',
        to: 'admin',
        show: isAdmin()
    }
]
