










import Form from '@/components/Form/Form.vue'
import {Component, Vue} from "vue-property-decorator";
import {Field, ifEmpty} from "@/types/Form";
import fetcher from "@/lib/fetcher";
import {getStoredUser} from "@/lib/user";

@Component({
  components: { Form: Form }
})
export default class ChangePasswordForm extends Vue {
  passwordChanged: boolean = false

  fields: Array<Field> = [
    {
      label: 'Введите новый пароль',
      name: 'password',
      type: 'password',
      hasErrors: [
        ifEmpty,
      ]
    },
    {
      label: 'Повторите',
      name: 'repeatPassword',
      type: 'password',
      hasErrors: [
        ifEmpty,
        (val: string) => {
          const anotherPassField = document.querySelector<HTMLInputElement>('[name="password"]')
          if (anotherPassField?.value !== val) {
            return 'пароли должны совпадать'
          }
        }
      ]
    }
  ]

  async onSubmit (fields: { [index: string]: string | undefined }) {
    getStoredUser().then(async user => {
      try {
        const json = await fetcher.put('/profile/change-password', {
          headers: new Headers({
            'Authorization': 'Bearer ' + user.accessToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify(fields),
        })
        if (json.status.code === 200) {
          this.passwordChanged = true
          setTimeout(() => {
            this.passwordChanged = false
            this.$emit('password-changed')
          }, 1500)
        } else {
          console.log('error:', json)
        }
      } catch (e) {
        console.log(e)
      }
    })
  }
}
