













import {Component, Prop, Vue} from 'vue-property-decorator'
import {ProductType} from "@/types/Product/ProductType";
import fetcher from "@/lib/fetcher";

@Component
export default class FirstLine extends Vue {
  @Prop() product: ProductType | undefined
  get toOrder () {
    const order = [{id: this.product?.id, count: 1 }]
    return { name: 'order', query: { cart: JSON.stringify(order) } }
  }

  get mainPictureUrl () {
    if (this.product?.mainPicture?.url)
      return fetcher.hostName + this.product?.mainPicture?.url
    else
      return fetcher.hostName + this.product?.pictures?.shift()?.file?.url
  }

  get shortDescription () {
    return `${this.product?.description?.substr(0, 80)}...`
  }
}
