















import Feedback from "@/components/Feedback.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import User from "@/types/User";

@Component({
  components: {Feedback}
})
export default class PreHeader extends Vue {
  @Prop() user!: User
  to = {id: 1}
}
