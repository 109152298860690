

















import {Component} from 'vue-property-decorator'
import GridView from "@/components/Admin/GridView.vue";
import Grid from "@/components/Interface/Grid.vue";
import {getFormattedDateTime} from "@/lib/date";

@Component({
  components: {Grid}
})
export default class Messages extends GridView {
  gridEndpoint: string = '/admin/message'

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    return {
      id: entity.id,
      created: getFormattedDateTime(entity.created, "dd.MM.yyyy hh:mm"),
      from_user: entity.from ? `<a href="/profile/admin/users/${entity.from?.id}">${entity.from?.id}</a>` : null,
      to_user: `<a href="/profile/admin/users/${entity.to?.id}">${entity.to?.id}</a>`,
      status: entity.status === 'EXPIRED' ? 'Отправлено' : 'Не отправлено'
    }
  }
}
