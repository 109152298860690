










import emitter from '@/emitter'
import User from '@/types/User'
import Footer from '@/components/Layout/Footer.vue'
import Header from '@/components/Layout/Header.vue'
import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({
  components: {Footer, Header}
})
export default class Layout extends Vue {

  @Prop() title!: string

  user: User = new User({isAuthorized: false})

  beforeMount() {
    if (this.title)
      document.title = this.title
    else document.title = 'Бонус-класс'
  }

  mounted () {
    emitter.on('user.login', incomingUser => {
      this.user = new User(incomingUser)
      this.user.isAuthorized = true
      localStorage.setItem("user", JSON.stringify(incomingUser))
    })

    emitter.on('user.logout', () => {
      localStorage.clear()
      this.user.isAuthorized = false
      this.$router.push({name: 'login', params: { back: 'profile' }})
    })

    const restoredUser = localStorage.getItem('user')
    if (restoredUser) emitter.emit('user.login', JSON.parse(restoredUser))
  }
}
