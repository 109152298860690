














import {Component} from 'vue-property-decorator'
import Grid from "@/components/Interface/Grid.vue";
import GridView from "@/components/Admin/GridView.vue";

@Component({
  components: {Grid}
})
export default class Products extends GridView {

  gridEndpoint: string = '/product'

  mounted () {
    const nav = document.querySelector<HTMLElement>('.horizontal-btn-nav')
    const last = nav?.querySelector<HTMLElement>('li:last-child')
    last?.classList.add('active')
  }

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    return {
      id: entity.id,
      title: entity.title,
      price: entity.price
    }
  }
}
