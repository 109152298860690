


























































import {Component, Vue} from 'vue-property-decorator'
import Layout from '@/components/Layout/Layout.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import {Crumb} from '@/types/Breadcrumbs'
import {getFormattedPhone, toUriJson, tryParseJSON, ucFirst} from '@/lib/formatter'
import {CartItem as CartItemType} from '@/types/CartItem'
import fetcher, {Fetcher} from "@/lib/fetcher";
import {ProductType} from "@/types/Product/ProductType";
import CartItem from '@/components/Order/CartItem.vue'
import Form from "@/components/Form/Form.vue";
import {Buyer} from "@/types/Buyer";
import {Order as OrderForm} from '@/types/Order'
import emitter from "@/emitter";
import {getParams} from "@/lib/params";
import User from "@/types/User";
import Agreement from "@/components/Interface/Agreement.vue";

@Component({
  components: {Agreement, Form: Form, Layout, Breadcrumbs, CartItem }
})
export default class Order extends Vue {

  name: string = 'Покупка'
  crumbs: Crumb[] = [{title: this.name}]
  userIsLoggingIn = false
  products: ProductType[] = []
  isSubmitError: boolean = false
  underFormText: string = ""
  submitErrorText: string = ""
  isUnderFormTextFetched: boolean = false
  underCartText: string = ""
  isUnderCartTextFetched: boolean = false
  isProfileFetched: boolean = false
  isProductsFetched: boolean = false
  offerPage: string = "#"
  isOfferPageFetched: boolean = false
  isRegionsFetched: boolean = false
  regionList: string[] = []

  form = {
    name: '',
    surname: '',
    phone: '',
    email: '',
    city: '',
    agree: false,
    otherCity: false,
  }

  get cart (): CartItemType[] {
    return tryParseJSON(this.$route?.query?.cart) || []
  }

  created() {
    Fetcher.redirectToLogout = false
    this.fetchProducts()
  }

  mounted() {
    this.userIsLoggingIn = !!JSON.parse(localStorage.getItem('user')!)

    fetcher.get('/profile').then(result => {
      if (result.status.code === 200) {
        const profile: User = result.data
        this.form.name = profile.firstName || ''
        this.form.surname = profile.lastName || ''
        this.form.phone = profile.phone ? getFormattedPhone(profile.phone) : ''
        this.form.email = profile.email || ''
        this.form.city = profile.city || ''
      }
    }).finally(() => this.isProfileFetched = true)

    fetcher.get('/settings?keys=order.offer.page').then(result => {
      if (result) this.offerPage = result[0].value
    }).finally(() => this.isOfferPageFetched = true)

    const keys = ['order.under-cart.text', 'order.under-form.text']

    getParams(keys).then(params => {
      this.underCartText = decodeURIComponent(params[keys[0]])
      this.underFormText = params[keys[1]]
      this.isUnderCartTextFetched = this.isUnderFormTextFetched = true
    })

    fetcher.get('/regions').then((regions: any) => {
      regions.map((region: any) => {
        this.regionList.push(region.name)
      })
    }).finally(() => this.isRegionsFetched = true)
  }

  onSubmitOrder () {
    const storedUser = JSON.parse(localStorage.getItem('user')!)
    if (!storedUser) {
      this.userIsLoggingIn = false
      this.clearOrderForm()
      return
    }

    if (this.isSubmitError) return;

    if (this.form.email.length === 0) {
      this.submitError("email не может быть пустым")
      return;
    }
    if (this.form.name.length === 0) {
      this.submitError("Ваше Имя не может быть пустым")
      return;
    }
    if (this.form.surname.length === 0) {
      this.submitError("Ваша Фамилия не может быть пустой")
      return;
    }
    if (this.form.phone.replace(/[^0-9]/, '').length < 11) {
      this.submitError("Ваш Телефон должен состоять минимум из 11 цифр")
      return;
    }
    if (this.regionList.indexOf(this.form.city) < 0) {
      this.submitError("Должен быть указан конкретный регион из выпадающего списка регионов или отмечена галочка о том что вашего региона нет в списке")
      return;
    }
    if (!this.form.agree) {
      this.submitError("Для оформления заказа необходимо согласие с офертой")
      return;
    }

    const orderBuyer = new Buyer({
      userId: storedUser.id,
      fullName: `${this.form.name} ${this.form.surname}`,
      phone: getFormattedPhone(this.form.phone),
      email: this.form.email,
      city: this.form.city,
    })

    const order = new OrderForm({id: null, basket: { compositions: this.cart.map(item => ({productId: item.id, count: item.count})) }, orderBuyer})
    fetcher.post('/order', fetcher.getJsonForm(order)).then(result => {
      if (result.status.code === 200) {
        this.getPaymentForm(result.data).submit()
      } else if (result.status.code === 401) {
        this.userIsLoggingIn = false
        emitter.emit('user.logout')
      }
    })
  }

  submitError (text: string) {
    this.submitErrorText = ucFirst(text) + "!"
    this.isSubmitError = true
    setTimeout(() => {
      this.isSubmitError = false
      this.submitErrorText = ''
    }, 2000)
  }

  onChangeItemCount (productId: number, count: number) {
    const filter = (item: CartItemType) => item.id === productId
    const fill = (item: CartItemType) => item.count = count
    this.cart.filter(filter).map(fill)
    history.pushState(null, document.title, '/order?cart=' + toUriJson(this.cart))
  }

  getProduct (id: number): ProductType | undefined {
    return this.products.filter((product: ProductType) => product.id === id).shift()
  }

  async fetchProducts () {
    this.isProductsFetched = true
    const response = await fetcher.get('/product/by-ids?ids=' + this.cart.map(item => item.id))
    if (response.status.code === 200) {
      response.data.map((params: any) => this.products.push(new ProductType(params)))
    }
  }

  onOtherRegionChange () {
    if (this.form.otherCity) {
      this.form.city = 'Другие страны мира'
    } else {
      this.form.city = ''
    }
  }

  getFormattedPhone () {
    if (this.form.phone.length > 0) {
      this.form.phone = getFormattedPhone(this.form.phone)
    }
  }

  private clearOrderForm () {
    this.form.name = ''
    this.form.surname = ''
    this.form.phone = ''
    this.form.email = ''
    this.form.city = ''
    this.form.agree = false
    this.form.otherCity = false
    document.querySelectorAll('input').forEach(element => element.blur())
  }

  private getPaymentForm (data: {[i: string]: string}) {

    const createInput = (name: string, value: string) => {
      const input: HTMLInputElement = <HTMLInputElement> document.createElement('INPUT')
      input.type = 'hidden'
      input.name = name
      input.value = value
      return input
    }

    const form: HTMLFormElement = <HTMLFormElement> document.createElement('FORM')
    form.action = 'https://bonusclass.server.paykeeper.ru/create/'
    form.method = 'POST'
    for (const name in data) {
      if (data.hasOwnProperty(name)) {
        form.appendChild(createInput(name, data[name]))
      }
    }
    document.querySelector('body')?.appendChild(form)
    return form
  }
}
