import fetcher from "@/lib/fetcher";

export async function getParam (key: string): Promise<string> {
    const result = await fetcher.get(`/settings?keys=${key}`)
    return result[0].value
}

export async function getParams(keys: Array<String>): Promise<{ [index: string]: string }> {
    const result: Array<{key: string, value: string}> = await fetcher.get('/settings?keys=' + keys.join(','))
    const params: {[index: string]: string} = {}
    result.forEach(param => { params[param.key] = param.value })
    return params
}
