





















import {Component, Vue} from "vue-property-decorator";
import Layout from "@/components/Layout/Layout.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {Crumb} from "@/types/Breadcrumbs";
import Sidebar from "@/components/Interface/Sidebar.vue";
import HorizontalBtnNav from "@/components/Interface/HorizontalBtnNav.vue";
import {NavItem} from "@/types/Sidebar";
import ProfileNav from "@/types/ProfileNav";

@Component({
  components: {HorizontalBtnNav, Sidebar, Breadcrumbs, Layout}
})
export default class Admin extends Vue {
  name: string = 'Администрирование'
  crumbs: Crumb[] = [{ title: 'Мой профиль', to: '/profile' }, {title: this.name}]

  horizontalItems: Array<NavItem> = ProfileNav

  sidebarItems = [
    {
      title: 'Настройки',
      to: 'admin',
      icon: 'gear'
    },
    {
      title: 'Заказы',
      to: 'admin/orders',
      icon: 'basket2'
    },
    {
      title: 'Транзакции баллов',
      to: 'admin/referral-transactions',
      icon: 'diagram-3'
    },
    {
      title: 'Вопросы и ответы',
      to: 'admin/faq',
      icon: 'question'
    },
    {
      title: 'Пользователи',
      to: 'admin/users',
      icon: 'people'
    },
    {
      title: 'Товары',
      to: 'admin/products',
      icon: 'handbag'
    },
    {
      title: 'Главная страница',
      to: 'admin/home-page',
      icon: 'house'
    },
    {
      title: 'Статические страницы',
      to: 'admin/pages',
      icon: 'blockquote-right'
    },
    {
      title: 'Сообщения',
      to: 'admin/messages',
      icon: 'envelope'
    },
    {
      title: 'Еженедельный отчет',
      to: 'admin/weekly-template',
      icon: 'inboxes'
    },
    {
      title: 'Рассылка',
      to: 'admin/mailing',
      icon: 'mailbox'
    },
    {
      title: 'Приватная статистика',
      to: 'admin/stat',
      icon: 'graph-up',
    },
    {
      title: 'Публичная Статистика',
      to: '/stat',
      target: '_blank',
      icon: 'box-arrow-up-right'
    }
  ]
}
