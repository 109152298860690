









import {Component, Prop, Vue} from 'vue-property-decorator';
import {BannerEntity} from "@/types/BannerEntity";
import fetcher from "@/lib/fetcher";

@Component({})
export default class SmallBannerModal extends Vue {
  @Prop() banner!: BannerEntity

  loading: boolean = true
  serverData: string | null = null;
  abortController!: AbortController

  beforeCreate() {
    this.abortController = new AbortController();
  }

  mounted() {
    this.loadData()
  }

  beforeDestroy() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  async loadData() {
    this.loading = true

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();

    try {
      const data = await fetcher.get(`/small-banners/${this.banner.id}`, {
        signal: this.abortController.signal
      });

      this.serverData = decodeURIComponent(data.html);

      this.loading = false
    } catch (error) {
      if (this.abortController.signal.aborted) {
        return;
      }
      console.error(error)
    }
  }
}
