import {StoredUser} from "@/types/User";

export function getStoredUserFromLocalStorage (): StoredUser | undefined {
    let user = undefined
    const localStorageUser = localStorage.getItem('user')
    if (localStorageUser) user = JSON.parse(localStorageUser)
    return user
}

export function getStoredUser() {
    return new Promise<StoredUser>((resolve, reject) => {
        const user = getStoredUserFromLocalStorage()
        if (user) {
            resolve(user)
        } else {
            reject("Пользователь не авторизован")
        }
    })
}

export function isAdmin (): boolean {
    let isAdmin = false
    const user = getStoredUserFromLocalStorage()
    if (user) {
        const adminAuthority = user.authorities?.filter(a => a.authority === "ROLE_ADMIN")
        if (adminAuthority != null && adminAuthority.length > 0) isAdmin = true
    }
    return isAdmin
}
