

































































import {Component, Prop, Vue} from "vue-property-decorator";
import ProductForm from "@/components/Admin/Product/ProductForm.vue";
import fetcher from "@/lib/fetcher";
import {uuid} from "@/lib/string";
import ImageLoader from "@/components/Interface/ImageLoader.vue";
import {FileEntity} from "@/types/FileEntity";

interface OtherImage {
  id: number | null,
  uuid: string,
  fileId: number | null
  entity: FileEntity | File | null
}

interface ProductParam {
  id?: number | null
  productId: number
  name: string
  value: string
  uuid: string
}

@Component({
  components: {ImageLoader, ProductForm}
})
export default class ProductEdit extends Vue {

  @Prop() id!: number
  values: {[index: string]: any} = {}
  isUpdateSuccess: boolean = false
  isUpdateError: boolean = false
  isProductFetched: boolean = false
  isParamsFetched: boolean = false
  isParamsSaved: boolean = false
  params: Array<ProductParam> = []
  deletedParams: Array<ProductParam> = []
  mainImageUuid: string = uuid()
  mainImageId: number | null = null
  mainImageFileEntity: { [index: string]: any } | null = null
  isMainImgUpdateSuccess: boolean = false
  isMainImgUpdateError: boolean = false
  product!: {[index: string]: any}
  isMainImageFetched: boolean = false
  otherImages: Array<OtherImage> = []
  deletedOtherImages: Array<OtherImage> = []
  isOtherImgFetched: boolean = false
  isOtherImgUpdateSuccess: boolean = false
  isOtherImgUpdateError: boolean = false

  beforeMount() {
    fetcher.get(`/admin/product/${this.id}`).then(product => {

      const values = {
        title: product.title,
        code: product.code,
        description: product.description,
        price: product.price,
        oldPrice: product.oldPrice,
        videoUrl: product.videoUrl,
        videoTypeId: product.videoType.id,
      }

      this.updateMainImage(product?.mainPicture?.id, product?.mainPicture?.name, product?.mainPicture)

      this.product = Object.assign({}, this.product, product)
      this.values = Object.assign({}, this.values, values)

      product.params?.map((param: any) => {
        this.params.push({
          id: param.id,
          name: param.name,
          value: param.value,
          productId: param.productId,
          uuid: uuid()
        })
      })

      product.pictures?.map((p: any) => this.otherImages.push({
        id: p.id,
        fileId: p.file.id,
        entity: p.file,
        uuid: uuid()
      }))
      this.addEmptyOtherImage()
      this.params.sort((prev: any, next: any) => prev.id - next.id);
    }).finally(() => this.isProductFetched = this.isParamsFetched = true)
  }

  onSubmit (fields: any) {
    fields.id = this.id
    fields.mainPictureId = this.mainImageId
    fetcher.put('/admin/product', fetcher.getJsonForm(fields)).then(() => {
      this.isUpdateSuccess = true
      setTimeout(() => this.isUpdateSuccess = false, 2000)
    }).catch(() => {
      this.isUpdateError = true
      setTimeout(() => this.isUpdateError = false, 2000)
    })
  }

  mounted () {
    const nav = document.querySelector<HTMLElement>('.horizontal-btn-nav')
    const last = nav?.querySelector<HTMLElement>('li:last-child')
    last?.classList.add('active')
  }

  onParamsSubmit () {
    const params4add = this.params.filter(p => p.id == null)
    const params4update = this.params.filter(p => p.id != null)
    const params4delete = this.deletedParams.filter(p => p.id != null)
    this.deletedParams.splice(0, this.deletedParams.length)
    this.manageParams(params4add, params4update, params4delete)
      .then(() => {
        this.isParamsSaved = true
        setTimeout(() => this.isParamsSaved = false, 2000)
      })
      .catch(() => {
        this.isUpdateError = true
        setTimeout(() => this.isUpdateError = false, 2000)
      })
  }

  onClickAddParam () {
    this.params.push({
      id: null,
      name: "",
      value: "",
      productId: this.id,
      uuid: uuid()
    })
  }

  onMainImageChange (file: File | null) {

    const success = () => {
      this.isMainImgUpdateSuccess = true
      setTimeout(() => this.isMainImgUpdateSuccess = false, 2000)
    }

    const error = () => {
      this.isMainImgUpdateError = true
      setTimeout(() => this.isMainImgUpdateError = false, 2000)
    }

    const product = this.product
    delete product.params
    delete product.pictures
    delete product.status

    if (this.mainImageId != null && file == null) {
      // если есть id и прислали null значит картинку хотят удалить
      // отвяжем ее от продукта и удалим картинку из бд
      product.mainPictureId = null
      fetcher.put('/admin/product', fetcher.getJsonForm(product)).then(() => {
        fetcher.delete(`/admin/file/${this.mainImageId}`).then(success).catch(success)
        this.updateMainImage(null, uuid(), null)
      }).catch(error)
    } else if (file != null) {
      const form = new FormData()
      //@ts-ignore
      form.append('file', file)
      // в остальных случаях просто отправим файл с id картинки на сервер,
      // а потом, если id-картинки изначально был null (т.е. мы не обновили файл, а добавили) обновим товар
      if (this.mainImageId == null) {
        fetcher.post('/admin/file', {
          body: form
        }).then(file => {
          product.mainPictureId = file?.id
          this.updateMainImage(file?.id, file?.name, file)
          fetcher.put('/admin/product', fetcher.getJsonForm(product)).then(success).catch(error)
        }).catch(error)
      } else {
        form.append('id', String(this.mainImageId))
        fetcher.put('/admin/file', { body: form }).then(file => {
          this.updateMainImage(file?.id, file?.name, file)
          success()
        }).catch(error)
      }
    }
  }

  onOtherImageChange (file: File | null, uuid: string) {
    const isExistInArray = this.otherImages.filter(img => img.uuid === uuid).length > 0
    if (isExistInArray) {
      this.otherImages.map(img => {
        if (img.uuid === uuid) {
          img.entity = file
          if (img.entity == null) {
            this.deletedOtherImages.push(img)
          }
        }
      })
    } else if (file != null) {
      this.addEmptyOtherImage(file, uuid)
    }

    this.otherImages = this.otherImages.filter(img => img.entity != null)
    this.addEmptyOtherImage()
  }

  addEmptyOtherImage (entity: any | null = null, u: string | null = null) {
    if (u == null) u = uuid()
    this.isOtherImgFetched = false
    this.otherImages.push({ id: null, fileId: null, uuid: u, entity: entity })
    this.isOtherImgFetched = true
  }

  onSaveOtherImages () {
    const images4add = this.otherImages.filter(i => i.entity != null && i.id == null)
    const images4upd = this.otherImages.filter(i => i.entity != null && i.entity instanceof File  && i.id != null)
    const images4del = this.deletedOtherImages.filter(i => i.entity == null && i.id != null)
    this.deletedOtherImages.splice(0, this.deletedOtherImages.length)
    this.manageOtherImages(images4add, images4upd, images4del).then(() => {
      this.isOtherImgUpdateSuccess = true
      setTimeout(() => this.isOtherImgUpdateSuccess = false, 2000)
    }).catch(() => {
      this.isOtherImgUpdateError = true
      setTimeout(() => this.isOtherImgUpdateError = false, 2000)
    }).finally(() => {
      this.isOtherImgFetched = false
      this.isOtherImgFetched = true
    })
  }

  updateMainImage (id: number | null, name: string, entity: {[index: string]: any} | null) {
    this.isMainImageFetched = false
    this.mainImageId = id
    this.mainImageUuid = name
    this.mainImageFileEntity = entity
    this.isMainImageFetched = true
  }

  async manageParams (a: ProductParam[], u: ProductParam[], d: ProductParam[]) {
    const endpoint = '/admin/product-param'
    await a.map(async p => await fetcher.post(endpoint, fetcher.getJsonForm(p)))
    await u.map(async p => await fetcher.put(endpoint, fetcher.getJsonForm(p)))
    await d.map(async p => await fetcher.delete(`${endpoint}/${p.id}`))
  }

  async manageOtherImages (a: Array<OtherImage>, u: Array<OtherImage>, d: Array<OtherImage>) {
    const endpoint = '/admin/product-picture'
    const form = (img: OtherImage) => {
      const form = new FormData()
      if (img.id != null) form.append('id', String(img.id))
      form.append('productId', String(this.id))
      if (img.fileId != null) form.append('file.id', String(img.fileId))
      // @ts-ignore
      form.append('file.file', img.entity)
      return {body: form}
    }
    await a.map(async i => await fetcher.post(endpoint, form(i)).then(e => {
      this.otherImages.map(img => {
        if (img.uuid === i.uuid) {
          img.entity = e.file
          img.fileId = e.file.id
          img.id = e.id
        }
      })
    }))
    await u.map(async i => await fetcher.put(endpoint, form(i)))
    await d.map(async i => await fetcher.delete(`${endpoint}/${i.id}`))
  }

  onClickDeleteParam (uuid: string) {
    const deletedParam = this.params.filter(p => p.uuid === uuid).shift()
    if (deletedParam !== undefined) this.deletedParams.push(deletedParam)
    this.params = this.params.filter(p => p.uuid !== uuid)
  }
}
