import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '@/components/Home/Home.vue'
import NotFound from '@/components/NotFound.vue'
import Profile from '@/components/Profile/Profile.vue'
import Login from '@/components/Auth/Login.vue'
import Registration from '@/components/Auth/Registration.vue'
import OAuth2 from '@/components/Auth/OAuth2.vue'
import Product from '@/components/Product/Product.vue'
import Order from '@/components/Order/Order.vue'

import {
  goOAuth2,
  goOutIfNotAdmin,
  goBackIfUserAuthorize,
  goLoginIfRouteAuthRequireAndUserIsNotAuthorize,
} from '@/lib/router'

import OrderDetail from '@/components/OrderDetail/OrderDetail.vue'
import ProfileStat from './components/ProfileStat/ProfileStat.vue'
import FirstLine from '@/components/ProfileStat/FirstLine.vue'
import OtherLines from '@/components/ProfileStat/OtherLines.vue'
import ReferralTransactions from '@/components/Admin/ReferralTransactions.vue'
import Admin from '@/components/Admin/Admin.vue'
import Users from '@/components/Admin/User/Users.vue'
import Orders from '@/components/Admin/Order/Orders.vue'
import Products from '@/components/Admin/Product/Products.vue'
import HomePage from '@/components/Admin/HomePage/HomePage.vue'
import Messages from '@/components/Admin/Messages/Messages.vue'
import Settings from '@/components/Admin/Settings.vue'
import FaqList from '@/components/Admin/Faq/FaqList.vue'
import Faq from '@/components/Faq/Faq.vue'
import EditUser from "@/components/Admin/User/EditUser.vue";
import CreateUser from "@/components/Admin/User/CreateUser.vue";
import ProductCreate from "@/components/Admin/Product/ProductCreate.vue";
import ProductEdit from "@/components/Admin/Product/ProductEdit.vue";
import MainSlider from "@/components/Admin/HomePage/MainSlider/MainSlider.vue";
import SmallBanners from "@/components/Admin/HomePage/SmallBanners/SmallBanners.vue";
import FaqCreate from "@/components/Admin/Faq/FaqCreate.vue";
import FaqEdit from "@/components/Admin/Faq/FaqEdit.vue";
import PageViewer from "@/components/PageView/PageViewer.vue";
import PageCreate from "@/components/Admin/Page/PageCreate.vue";
import PageEdit from "@/components/Admin/Page/PageEdit.vue";
import Pages from "@/components/Admin/Page/Pages.vue";
import OrderEdit from "@/components/Admin/Order/OrderEdit.vue";
import MessageView from "@/components/Admin/Messages/MessageView.vue";
import Stat from "@/components/Stat/Stat.vue";
import StatByCity from "@/components/Stat/StatByCity.vue";
import StatByPeriod from "@/components/Stat/StatByPeriod.vue";
import ConfirmEmail from "@/components/ConfirmEmail/ConfirmEmail.vue";
import ResetPassword from "@/components/ResetPassword/ResetPassword.vue";
import WeeklyTemplate from "@/components/Admin/WeeklyTemplate.vue";
import AdminStat from "@/components/Admin/Stat/AdminStat.vue";
import Mailing from "@/components/Admin/Mailing/Mailing.vue";
import PaymentSuccess from "@/components/PaymentSuccess/PaymentSuccess.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: goLoginIfRouteAuthRequireAndUserIsNotAuthorize,
    props: true
  },
  {
    path: '/profile/stat',
    component: ProfileStat,
    beforeEnter: goLoginIfRouteAuthRequireAndUserIsNotAuthorize,
    props: true,
    children: [
      {
        path: '',
        component: FirstLine,
      },
      {
        path: 'deeper',
        component: OtherLines,
      }
    ]
  },
  {
    path: '/profile/admin',
    component: Admin,
    beforeEnter: goOutIfNotAdmin,
    props: true,
    children: [
      {
        path: '',
        component: Settings,
      },
      {
        path: 'users',
        component: Users,
      },
      {
        name: 'create-user',
        path: 'users/create',
        component: CreateUser
      },
      {
        path: 'users/:id',
        name: 'edit-user',
        component: EditUser,
        props: true,
      },
      {
        path: 'orders',
        component: Orders,
      },
      {
        name: 'orders-edit',
        path: 'orders/:id',
        component: OrderEdit,
        props: true,
      },
      {
        path: 'referral-transactions',
        component: ReferralTransactions,
      },
      {
        path: 'products',
        component: Products,
      },
      {
        name: 'products-create',
        path: 'products/create',
        component: ProductCreate,
      },
      {
        name: 'products-edit',
        path: 'products/:id',
        component: ProductEdit,
        props: true,
      },
      {
        name: 'home-page',
        path: 'home-page',
        component: HomePage,
      },
      {
        name: 'main-slider',
        path: 'home-page/main-slider',
        component: MainSlider,
      },
      {
        name: 'small-banners',
        path: 'home-page/small-banners',
        component: SmallBanners,
      },
      {
        path: 'home-page',
        component: HomePage,
      },
      {
        path: 'faq',
        component: FaqList,
      },
      {
        path: 'faq/create',
        name: 'faq-create',
        component: FaqCreate,
      },
      {
        path: 'faq/:id',
        name: 'faq-edit',
        component: FaqEdit,
        props: true
      },
      {
        path: 'pages',
        component: Pages,
      },
      {
        path: 'pages/create',
        name: 'create-page',
        component: PageCreate,
      },
      {
        path: 'pages/:id',
        name: 'edit-page',
        component: PageEdit,
        props: true,
      },
      {
        path: 'messages',
        component: Messages,
      },
      {
        path: 'messages/view/:id',
        name: 'edit-message',
        component: MessageView,
        props: true,
      },
      {
        path: 'weekly-template',
        name: 'weekly-template',
        component: WeeklyTemplate,
      },
      {
        path: 'stat',
        name: 'admin-stat',
        component: AdminStat,
      },
      {
        path: 'mailing',
        name: 'mailing',
        component: Mailing
      }
    ]
  },
  {
    path: '/faq',
    component: Faq,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: goBackIfUserAuthorize,
    props: true
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: ResetPassword,
    beforeEnter: goBackIfUserAuthorize,
  },
  {
    path: '/user/oauth2/:source',
    name: 'oauth2',
    component: OAuth2,
    beforeEnter: goOAuth2,
    props: true
  },
  {
    path: '/inv/:refId',
    name: 'register',
    component: Registration,
    props: true
  },
  {
    path: '/product/:code',
    name: 'product',
    component: Product,
    props: true
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
  },
  {
    path: '/page/:pageCode',
    component: PageViewer,
    props: true
  },
  {
    path: '/order-detail/:orderId',
    name: 'orderDetail',
    component: OrderDetail,
    props: true,
  },
  {
    path: '/payment-success/:orderId',
    name: 'paymentSuccess',
    component: PaymentSuccess,
    props: true,
  },
  {
    path: '/stat',
    name: 'stat',
    component: Stat,
    children: [
      {
        name: 'stat-by-city',
        path: 'by-city',
        component: StatByCity
      },
      {
        name: 'stat-by-period',
        path: 'by-period/:period',
        component: StatByPeriod,
        props: true
      },
    ]
  },
  {
    name: 'confirm-email',
    path: '/confirm-email',
    component: ConfirmEmail,
  },
  {
    path: '*',
    component: NotFound,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
