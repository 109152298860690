import {getStoredUser} from "@/lib/user";
import emitter from "@/emitter";

export class Fetcher {

    readonly host: string = location.hostname
    readonly port: string = '443'
    readonly path: string = '/rest'

    public static redirectToLogout: Boolean = true

    get hostName(): string {
        return '//' + this.host + (this.port ? ':' + this.port : '')
    }

    get url(): string {
        return '//' + this.host + (this.port ? ':' + this.port : '') + (this.path ? this.path : '')
    }

    private static async getResponse (response: Response) {
        if (!response.ok) throw Error(response.statusText);
        try {
            const json = await response.json()
            if (this.redirectToLogout && json.status?.code === 401) emitter.emit('user.logout')
            return json
        } catch (e) {
            return response.ok
        }
    }

    private async any (method: string, route: string, params: any) {
        params = await Fetcher.trySign(params)
        const response = await fetch(this.url + route, { method: method.toUpperCase(), ...params })
        return Fetcher.getResponse(response)
    }

    async post (route: string, params: any) {
        return this.any('post', route, params)
    }

    async get (route: string, params: any = null) {
        return this.any('get', route, params)
    }

    async put (route: string, params: any) {
        return this.any('put', route, params)
    }

    async delete (route: string, params: any = null) {
        return this.any('delete', route, params)
    }

    private static async trySign (params: any) {
        let user = null
        try { user = await getStoredUser() } catch (e) {}
        if (params == null) params = {}
        if (params.headers == null) params.headers = new Headers()
        if (user != null) params.headers.set('Authorization', 'Bearer ' + user.accessToken)
        return params
    }

    getJsonForm (form: any) {
        return {
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(form),
        }
    }
}

const fetcher = new Fetcher
export default fetcher
