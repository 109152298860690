export function getFormattedDateTime (dateTime: Date | string | undefined, format: string): string | undefined {

    if (dateTime == null) return undefined
    if (format == null) return new Date(dateTime).toString()

    const date = new Date(dateTime)

    const getNum = (num: number) => { return num < 10 ? `0${num}` : `${num}` }

    const year = String(date.getFullYear())
    const month = getNum(date.getMonth() + 1)
    const day = getNum(date.getDate())
    const hour = getNum(date.getHours())
    const minute = getNum(date.getMinutes())
    const sec = getNum(date.getSeconds())

    // noinspection JSUnusedGlobalSymbols
    const availablePatterns: { [index: string]: () => string } = {
        yyyy: () => { return year },
        yy: () => { return year.substr(2, 2) },
        MM: () => { return month },
        dd: () => { return day },
        hh: () => { return hour },
        mm: () => { return minute },
        ss: () => { return sec }
    }

    const formatter = (value: string) => {

        let stack = []
        let result = ""
        const array = value.split('')

        const flushStack = (stack: any) => {
            const pattern = stack.join('')
            const stringifier = availablePatterns[pattern];
            result += stringifier ? stringifier() : pattern
        }

        for (let i = 0; i < array.length; i++) {
            let current = array[i]
            let next = array[i + 1]
            if (current !== next) {
                stack.push(current)
                flushStack(stack)
                stack = []
            } else stack.push(current)
        }
        return result;
    }

    return formatter(format)
}

export function getDifference (date1: Date, date2: Date) {
    let diffMs = Math.abs(date1.getTime() - date2.getTime()) / 1000; // milliseconds between date1 & date2
    let day = Math.floor(diffMs / 86400);
    let hour = Math.floor((diffMs % 86400) / 3600);
    let min = Math.floor(((diffMs % 86400) % 3600) / 60);
    let sec = Math.floor(((diffMs % 86400) % 3600) % 60);
    return {day, hour, min, sec}
}

export function getEndWeekTimer (now: Date, created: Date) {
    const nowDifference = getDifference(created, now)
    // количество прошедших недель
    const passedWeeks = Math.floor(nowDifference.day / 7)
    // количество дней прошедших с начала текущей недели
    const passedDaysOfLastWeek = nowDifference.day - (7 * passedWeeks)
    // когда неделя началась
    const whenWeekStarted = new Date(now.getTime())
    whenWeekStarted.setDate(now.getDate() - passedDaysOfLastWeek)
    whenWeekStarted.setHours(created.getHours())
    whenWeekStarted.setMinutes(created.getMinutes())
    whenWeekStarted.setSeconds(created.getSeconds())
    // когда неделя закончится
    const whenEndOfWeek = new Date(whenWeekStarted.getTime())
    whenEndOfWeek.setHours(whenWeekStarted.getHours())
    whenEndOfWeek.setMinutes(whenWeekStarted.getMinutes())
    whenEndOfWeek.setSeconds(whenWeekStarted.getSeconds())
    whenEndOfWeek.setDate(whenWeekStarted.getDate() + 7)

    return getDifference(now, whenEndOfWeek);
}

export function dateFromInstant (instant: string): string {
    if (instant == null || instant.length === 0) return ''
    return <string> instant.split('T').shift()
}

export function instantFromDate (date: string): string {
    if (date == null || date.length === 0) return ''
    return date.split('T').shift() + 'T00:00:00Z'
}
