

















import {Component, Vue, Watch} from "vue-property-decorator";
import Layout from "@/components/Layout/Layout.vue";
@Component({
  components: {Layout}
})
export default class Stat extends Vue {

  isMain: boolean = false
  isStatByPeriod: boolean = false
  isStatByCity: boolean = false

  beforeMount() {
    this.onRouteChange()
  }

  @Watch('$route')
  onRouteChange() {
    if (this.$route.name === 'stat-by-city') {
      this.isMain = false
      this.isStatByCity = true
      this.isStatByPeriod = false
    } else if (this.$route.name === 'stat-by-period') {
      this.isMain = false
      this.isStatByCity = false
      this.isStatByPeriod = true
    } else {
      this.isMain = true
      this.isStatByCity = false
      this.isStatByPeriod = false
    }
  }
}
