









import {Component, Vue} from "vue-property-decorator";
import Layout from "@/components/Layout/Layout.vue";
import emitter from "@/emitter";
import fetcher from "@/lib/fetcher";

@Component({
  components: {Layout}
})
export default class ConfirmEmail extends Vue {

  showError = false

  beforeMount() {
    if (this.$route.query.token) {
      localStorage.setItem("user", JSON.stringify({accessToken: this.$route.query.token}))
      fetcher.get('/user/confirm-email?token=' + this.$route.query.token).then(async response => {
        if (response.status.code === 200) {
          localStorage.removeItem("user")
          emitter.emit('user.login', response.data)
          await this.$router.push({name: 'profile'})
        } else {
          this.showError = true
        }
      })
    }
  }
}
