













import {Component, Prop, Vue} from 'vue-property-decorator'
import LineCard from "@/components/ProfileStat/LineCard.vue";
import UserForProfileStatFirstLine from "@/types/UserForProfileStatFirstLine";

@Component({
  components: {LineCard}
})
export default class FirstLine extends Vue {
  @Prop() lines!: Array<UserForProfileStatFirstLine>

  get linesLength () {
    let counter = 0
    for (const line in this.lines) counter += 1
    return counter
  }
}
