








import {Component, Prop, Vue} from "vue-property-decorator";
import Form from '@/components/Form/Form.vue';
import {Field, ifEmpty, ifNotMatch, maxLength} from "@/types/Form";
import fetcher from "@/lib/fetcher";
import {RoleEntity} from "@/types/RoleEntity";
import {FormSelectOption} from "@/types/FormSelectOption";
import {getCleanPhone} from "@/lib/formatter";
import {dateFromInstant, instantFromDate} from "@/lib/date";

@Component({
  components: {Form: Form}
})
export default class UserForm extends Vue {

  @Prop() fieldValues!: {[index: string]: any}
  statuses: Array<{[index: string]: string}> = [
    {'ACTIVE': 'Активен'},
    {'UNACTIVATED': 'Не активирован'},
    {'BLOCKED': 'Заблокирован'}
  ]
  isRolesFetched: boolean = false

  fields: Array<Field> = [
    {
      label: 'Логин',
      name: 'username',
      hasErrors: [
        ifEmpty,
        (val: string) => { return maxLength(val, 255) },
        (val: string) => {
          const regexp = new RegExp('^[a-zA-Z\_0-9]+$')
          const errorText = 'логин может содержать только латинские буквы, цифры и символ нижнего подчеркивания'
          return ifNotMatch(val, regexp, errorText)
        },
      ]
    },
    {
      label: 'Логин-ID пригласившего',
      name: 'refId',
      hasErrors: [ifEmpty, (val: string) => { return maxLength(val, 9) },]
    },
    {
      label: 'Фамилия',
      name: 'lastName',
      hasErrors: (val: string) => { return maxLength(val, 255) },
    },
    {
      label: 'Имя',
      name: 'firstName',
      hasErrors: (val: string) => { return maxLength(val, 255) },
    },
    {
      label: 'Отчество',
      name: 'middleName',
      hasErrors: (val: string) => { return maxLength(val, 255) },
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      hasErrors: [ifEmpty, (val: string) => { return maxLength(val, 255) },]
    },
    {
      label: 'Город',
      name: 'city',
      hasErrors: (val: string) => { return maxLength(val, 255) }
    },
    {
      label: 'Телефон',
      name: 'phone',
      placeholder: '+7 XXX XXX XX XX',
      hasErrors: (val: string) => {
        const length = String(val).replace(/[^0-9]/g, '').length
        if (length > 0 && length < 11) {
          return 'в номере телефона должно быть 11 цифр'
        }
      }
    },
    {
      label: 'Пароль',
      name: 'password',
    },
    {
      label: 'День рождения',
      name: 'birthdate',
      type: 'date',
    },
    {
      label: 'Роли',
      name: 'roles',
      type: 'select',
      multiple: true,
    },
    {
      label: 'Статус',
      name: 'status',
      type: 'select',
    }
  ]

  beforeMount() {
    fetcher.get('/admin/roles').then(response => {
      const roleIndex = this.fields.findIndex((element: Field) => { return element.name === 'roles' })
      this.fields[roleIndex].value = []
      this.fields.map((field: Field, i: number) => {
        const excluded = ['roles', 'status']
        if (excluded.indexOf(field.name) < 0 && this.fieldValues[field.name] != null) {
          this.fields[i].value = this.fieldValues[field.name]
        }
        if (field.name === 'birthdate') {
          this.fields[i].value = dateFromInstant(this.fieldValues[field.name])
        }
        if (field.name === 'status') {
          if (this.fields[i].value == null) this.fields[i].value = []
          this.statuses.map(status => {
            for (const s in status) {
              this.fields[i].value.push({
                value: s,
                label: status[s],
                selected: this.fieldValues['status'] === s
              })
            }
          })
        }
      })
      response.map((role: RoleEntity) => {
        if (role.status === "ACTIVE") {
          this.fields[roleIndex].value.push({
            value: String(role.id),
            label: role.name,
            selected: this.fieldValues['roles']?.indexOf(role.id) > -1,
          })
        }
      })

    }).finally(() => this.isRolesFetched = true)
  }

  onSubmit (fields: any) {
    fields.roles = this.prepareSelectArray(fields.roles)
    if (fields.phone.length) fields.phone = getCleanPhone(fields.phone)
    fields.status = this.prepareSelectArray(fields.status).shift()
    fields.birthdate = instantFromDate(fields.birthdate)
    this.$emit('submit', fields)
  }

  prepareSelectArray (roles: Array<FormSelectOption>): Array<any> {
    const preparedRoles: Array<any> = []
    roles.map(role => { if (role.selected) preparedRoles.push(role.value) })
    return preparedRoles
  }
}
