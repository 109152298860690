














import Component from "vue-class-component";
import Vue from "vue";
import fetcher from "@/lib/fetcher";
import {sortByNumericField} from "@/lib/sort";
import {FileEntity} from "@/types/FileEntity";
import {SlideEntity} from "@/types/SlideEntity";
import VideoAdapter from "@/components/VideoAdapter/VideoAdapter.vue";
import emitter from "@/emitter";
import {getContentWidth} from "@/lib/dom";
@Component({
  components: {VideoAdapter}
})
export default class Slider extends Vue {
  isSlidesFetched: boolean = false
  slides: Array<SlideEntity> = []
  defaultCarouselInterval: number = 5000
  carouselInterval: number = this.defaultCarouselInterval
  isVideoPlayed: boolean = false
  isNeedIndicators: boolean = true
  isNeedControls: boolean = true

  backgroundUrl (background: FileEntity) {
    return fetcher.hostName + background?.url
  }

  mounted() {
    window.addEventListener('blur',() => {
      if (document?.activeElement?.tagName?.toLowerCase() == 'iframe') {
        this.isVideoPlayed = true
        this.isNeedIndicators = false
        this.carouselInterval = 0
      }
    })
    fetcher.get('/home-slider')
        .then(slides => this.slides = Object.assign({}, sortByNumericField(slides, 'position')))
        .finally(() => {
          this.isSlidesFetched = true
          this.isNeedIndicators = this.needIndicators()
          this.isNeedControls = this.isNeedIndicators
        })
  }

  switchVideoState (i: number) {
    const slide = this.slides[i]
    if (slide.isVideo) {
      const slider = document.querySelector<HTMLElement>('#main-slider')
      if (slider) emitter.emit('need.fix.height', getContentWidth(slider))
    } else {
      this.isVideoPlayed = false
      this.isNeedIndicators = this.needIndicators()
      this.carouselInterval = this.defaultCarouselInterval
    }
  }

  needIndicators() {
    let indicatorsLength = 0
    for (const i in this.slides) {
      if (this.slides.hasOwnProperty(i)) indicatorsLength++
    }
    return indicatorsLength > 1
  }

  getCarouselControls () {
    const controls = document.querySelectorAll<HTMLElement>('.carousel-control-next, .carousel-control-prev')
    return controls ? controls : []
  }

  onSlideClick (slide: SlideEntity) {
    if (!slide.isVideo && slide.link) location.href = slide.link
  }

  onVideoMounted (frameId: string) {
    const video = document.querySelector<HTMLElement>(`[data-frame="${frameId}"]`)
    if (video && video.tagName.toLowerCase() === 'video') {
      video.addEventListener('play', () => {
        this.isVideoPlayed = true
        this.isNeedIndicators = false
        this.carouselInterval = 0
      })
      video.addEventListener('pause', () => {
        this.isVideoPlayed = false
        this.isNeedIndicators = this.needIndicators()
        this.carouselInterval = this.defaultCarouselInterval
      })
    }
  }
}
