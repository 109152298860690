












import {Component, Prop, Vue} from "vue-property-decorator";
import {Field, ifEmpty, ifNotMatch} from "@/types/Form";
import Form from "@/components/Form/Form.vue";
import fetcher from "@/lib/fetcher";

@Component({
  components: {Form}
})
export default class ProductForm extends Vue {

  @Prop() fieldValues!: {[index: string]: any}

  isVideoTypesFetched: boolean = false
  fields: Array<Field> = [
    {
      name: 'title',
      label: 'Название',
      hasErrors: ifEmpty
    },
    {
      name: 'code',
      label: 'Символьный код',
      hasErrors: (val: string) => {
        const regexp = new RegExp('^[a-zA-Z-\_0-9]+$')
        const errorText = 'символьный код может содержать только латинские буквы, цифры, символ нижнего подчеркивания и дефис'
        return val.length > 0 && ifNotMatch(val, regexp, errorText)
      }
    },
    {
      name: 'description',
      label: 'Описание',
      type: 'textarea',
    },
    {
      name: 'price',
      label: 'Цена',
      type: 'number',
      hasErrors: (val: string) => {
        if (parseInt(val, 10) < 0) return 'цена не может быть отрицательной'
      }
    },
    {
      name: 'oldPrice',
      label: 'Старая цена',
      type: 'number',
      hasErrors: (val: string) => {
        if (parseInt(val, 10) < 0) return 'цена не может быть отрицательной'
      }
    },
    {
      name: 'videoUrl',
      label: 'Ссылка на видео'
    }
  ]

  onSubmit (fields: any) {
    const videoTypes = fields.videoTypeId
    for (let i in videoTypes) {
      if (videoTypes.hasOwnProperty(i) && videoTypes[i].selected) {
        fields.videoTypeId = videoTypes[i].value
        break
      }
    }
    this.$emit('submit', fields)
  }

  beforeMount() {
    this.fields.map((field: Field, i: number) => this.fields[i].value = this.fieldValues[field.name])
    fetcher.get('/video-type').then(response => {
      const videoTypes: Field = { name: 'videoTypeId', label: 'Тип видео', type: 'select', size: 1, value: [] }
      response.map((vType: any) => videoTypes.value.push({
        value: String(vType.id),
        label: vType.displayValue,
        selected: this.fieldValues['videoTypeId'] === vType.id,
      }))
      this.fields.push(videoTypes)
    }).finally(() => this.isVideoTypesFetched = true)
  }
}
