









import {Component, Vue} from "vue-property-decorator";
import ProductForm from "@/components/Admin/Product/ProductForm.vue";
import fetcher from "@/lib/fetcher";
@Component({
  components: {ProductForm}
})
export default class ProductCreate extends Vue {

  isSaveError: boolean = false
  defaultValues: {[i: string]: any} = {
    'price': 0,
    'oldPrice': 0,
    'videoTypeId': 1
  }

  mounted () {
    const nav = document.querySelector<HTMLElement>('.horizontal-btn-nav')
    const last = nav?.querySelector<HTMLElement>('li:last-child')
    last?.classList.add('active')
  }

  onSubmit (fields: any) {
    fetcher.post('/product',   fetcher.getJsonForm(fields)).then(result => {
      this.$router.push({name: 'products-edit', params: {id: result.id}})
    })
  }
}
