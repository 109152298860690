























import {Component, Prop, Vue} from "vue-property-decorator";
import {FileEntity} from "@/types/FileEntity";
import {uuid} from "@/lib/string";
import fetcher from "@/lib/fetcher";

@Component
export default class ImageLoader extends Vue {

  @Prop() fileEntity: FileEntity | File | null | undefined
  @Prop() uuid!: string

  localUuid = this.uuid == null ? uuid() : this.uuid

  imgAlt = ''
  imgUrl = ''
  isImgFetched: boolean = false

  getFileInput (): HTMLInputElement | null {
    return document.querySelector<HTMLInputElement>(`[name="input-${this.localUuid}"]`)
  }

  onLoadImage () { this.getFileInput()?.click() }

  mounted() {
    this.loadImg(this.fileEntity)
  }

  onDeleteImage () {
    const fileInput = this.getFileInput()
    if (fileInput != null) {
      fileInput.value = ''
      this.isImgFetched = false
      this.imgAlt = ''
      this.imgUrl = ''
      this.$emit('change', null, this.localUuid)
    }
  }

  onChangePhoto () {
    const file = this.getFileInput()?.files?.item(0)
    this.loadImg(file)
    this.$emit('change', file, this.localUuid)
  }

  loadImg (entity: FileEntity | File | null | undefined) {
    this.isImgFetched = false
    if (entity instanceof File) {
      this.isImgFetched = false
        const fr = new FileReader()
        fr.readAsDataURL(entity)
        fr.onload = () => {
          this.imgUrl = String(fr.result)
          this.isImgFetched = true
          this.imgAlt = entity.name
      }
    } else if (entity != null && entity.hasOwnProperty('url') && entity.url) {
      const alt = entity?.name
      const url = entity?.url?.substr(0, 1) === '/' ? fetcher.hostName + entity?.url : entity?.url
      if (url) this.imgUrl = url
      if (alt) this.imgAlt = alt
      this.isImgFetched = true
    }
  }
}
