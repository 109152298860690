











import {Component, Prop, Vue} from 'vue-property-decorator'
import {getFormattedDateTime} from "@/lib/date";
import {ucFirst} from "@/lib/formatter";
import AdaptiveTable from "@/components/Interface/AdaptiveTable.vue";

@Component({
  components: {AdaptiveTable}
})
export default class OrderView extends Vue {

  @Prop() order!: {[index: string]: any}
  rows: Array<Array<string>> = []

  fields: any  = [
    { key: 'title', label: 'Товар', thClass: 'th-header' },
    { key: 'count', label: 'Кол-во', thClass: 'th-header' },
    { key: 'price', label: 'Цена', thClass: 'th-header' },
  ]

  items: any = []

  get buyerFullName () {
    return this.order?.buyer?.fullName
  }

  get orderCreated () {
    return getFormattedDateTime(this.order.created, 'hh:mm:ss yyyy/MM/dd')
  }

  get orderUpdated () {
    return getFormattedDateTime(this.order.updated, 'hh:mm:ss yyyy/MM/dd')
  }

  get orderStatus () {
    return ucFirst(this.order?.orderStatus?.displayValue)
  }

  get orderTotal () {
    let total = 0
    this.items.map((item: any) => total += (item.count * item.price))
    return total
  }

  mounted () {
    this.order.basket.compositions.forEach((item: any) => {
      this.items.push({
        title: item.product.title,
        count: item.count,
        price: item.price,
      })
    })
    this.rows.push(['Статус', String(this.orderStatus)],
        ['Покупатель', String(this.buyerFullName)],
        ['Дата заказа', String(this.orderCreated)])
  }
}
