






























import {Component, Prop, Vue} from "vue-property-decorator";
import {BannerEntity} from "@/types/BannerEntity";
import TextEditor from "@/components/Interface/TextEditor.vue";
import ImageLoader from "@/components/Interface/ImageLoader.vue";
@Component({
  components: {ImageLoader, TextEditor}
})
export default class BannerForm extends Vue {

  @Prop() banner!: BannerEntity
  showModalHtml: boolean = this.banner.isModal

  toggleShowModalHtml () {
    this.showModalHtml = !this.showModalHtml
    this.banner.isModal = this.showModalHtml
  }

  toggleIsHidden () {
    this.banner.isHidden = !this.banner.isHidden
  }

  onBannerSubmit () {
    this.$emit('submit', this.banner)
  }

  onChangeModalHtml (e: any) {
    this.banner.modalHtml = encodeURIComponent(e.html)
  }

  onChangeIcon (file: File) {
    this.banner.iconFile = file
  }
}
