

























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Layout from "@/components/Layout/Layout.vue";
import {PageEntity} from "@/types/PageEntity";
import fetcher from "@/lib/fetcher";

@Component({
  components: {Layout}
})
export default class PageViewer extends Vue {

  @Prop() pageCode: string | null | undefined

  isPageFetched: boolean = false
  page: PageEntity = {
    code: "404",
    id: 0,
    text: "NOT FOUND",
    header: "Страница не найдена",
    title: "404"
  }
  isWelcome: boolean = false

  get pageContent() {
    const content = decodeURIComponent(this.page.text)
    const contentElement = document.createElement('DIV')
    contentElement.innerHTML = content
    const lastElement = <HTMLElement> contentElement.lastChild
    if (lastElement.innerHTML?.trim() === '<br>') {
      contentElement.removeChild(lastElement)
      return contentElement.innerHTML
    }
    return content
  }

  get isSoloPage () {
    const soloPages = ['welcome']
    return soloPages.indexOf(String(this.pageCode)) > -1
  }

  beforeMount() {
    if (this.pageCode === 'welcome') {
      const refId = this.$route.query.refId
      fetcher.get('/user/check-referral-id?refId=' + refId).then(json => {
        if (json.status.code !== 200) { this.$router.push(`/`) }
      })
      this.isWelcome = true
    }
    this.tryLoadPage()
  }

  @Watch('pageCode')
  tryLoadPage () {
    if (!this.pageCode) this.go404()
    this.isPageFetched = false
    fetcher.get(`/page/by-code/${this.pageCode}`)
        .then((page: PageEntity) => {
          this.page.code = page.code
          this.page.id = page.id
          this.page.text = page.text
          this.page.header = page.header
          this.page.title = page.title
          if (this.pageCode === 'welcome') {
            document.title = this.page.title
          }
        })
        .catch(() => this.go404())
        .finally(() => this.isPageFetched = true)
  }

  go404 () {
    this.$router.push('/404')
  }

  onRegisterClick () {
    const refId = this.$route.query.refId
    this.$router.push(`/inv/${refId}`)
  }
}
