
































import {Component, Prop, Vue} from 'vue-property-decorator'
import AdaptiveTable from '@/components/Interface/AdaptiveTable.vue'
import {Page} from '@/types/Page'
import fetcher from "@/lib/fetcher";

@Component({
  components: {AdaptiveTable}
})
export default class Grid extends Vue {
  @Prop() page!: Page<any>
  @Prop() name!: string
  @Prop() withoutCreate!: boolean
  @Prop() withoutEdit!: boolean
  @Prop() withoutDelete!: boolean
  @Prop() singleName!: string
  @Prop() deleteUrl: string | null | undefined
  @Prop() withoutRemoveRowAfterDelete!: boolean
  @Prop() withView!: boolean
  @Prop() beforeDeleteText!: string
  localWithView: boolean = this.withView ? this.withView : false
  localDeleteUrl = this.deleteUrl != null ? this.deleteUrl : location.pathname
  size = this.page.size
  totalRows = this.page.totalElements
  totalPages = this.page.totalPages
  currentPage = this.page.pageable.pageNumber + 1
  rows = [this.entityKeys, ...this.entityValues]

  get entityKeys () {
    const keys = []

    for (const i in this.page.content) {
      if (this.page.content.hasOwnProperty(i))
        for (const key in this.page.content[i])
          if (this.page.content[i].hasOwnProperty(key) && keys.indexOf(key) < 0)
            if (key.toLowerCase().trim() === 'id') {
              keys.unshift(key)
            } else {
              keys.push(key)
            }
    }

    return keys
  }

  get entityValues() {
    const values: any[][] = []

    for (const i in this.page.content) {
      if (this.page.content.hasOwnProperty(i)) {
        const value: any[] = []
        for (const key in this.page.content[i]) {
          if (this.page.content[i].hasOwnProperty(key)) {
            if (key.toLowerCase().trim() === 'id')
              value.unshift(this.page.content[i][key as string])
            else
              value.push(this.page.content[i][key as string])
          }
        }
        values.push(value)
      }
    }

    return values
  }

  get currentRange (): { first: number, last: number } {
    return {
      first: this.page.pageable.offset + 1,
      last: this.page.pageable.offset + this.page.content.length
    }
  }

  onSizeChange () {
    if (this.size < 1) this.size = 1
    this.$router.push({path: location.pathname, query: Object.assign({}, this.$route.query, { size: this.size })})
    this.$emit('change-page-size', this.size)
  }

  onPageChange (nextPage: any) {
    if (nextPage < 1) nextPage = 1
    this.$router.push({path: location.pathname, query: Object.assign({}, this.$route.query, { page: nextPage })})
    this.$emit('change-page-number', nextPage)
  }

  onClickCreate () {
    this.$router.push(location.pathname + '/create')
  }

  onClickView (event: Event) {
    this.getParentBySelector(event.target as HTMLElement, "[data-id]").then(parent => {
      this.$router.push(location.pathname + '/view/' + parent.getAttribute('data-id'))
    }).catch(() => console.log('не удалось найти родителя с атрибутом data-id'))
  }

  onClickEdit (event: Event) {
    this.getParentBySelector(event.target as HTMLElement, "[data-id]").then(parent => {
      this.$router.push(location.pathname + '/' + parent.getAttribute('data-id'))
    }).catch(() => console.log('не удалось найти родителя с атрибутом data-id'))
  }

  onClickDelete (event: Event) {
    const targetElement = event.target as HTMLElement
    this.getParentBySelector(targetElement, 'tr[data-id]').then(row => {
      const id = row.getAttribute('data-id')
      const parent = row.parentNode
      let confirmAgreement = "Вы уверены что хотите удалить " + this.singleName + " с id " + id + "?"
      if (this.beforeDeleteText) confirmAgreement = this.beforeDeleteText
      if (parent && confirm(confirmAgreement)) {
        fetcher.delete(this.localDeleteUrl + '/' + id).then(() => {
          if (this.withoutRemoveRowAfterDelete) {
            this.$emit('change-page-size', this.size)
          } else { parent.removeChild(row) }
        })
      }
    })
  }

  private getParentBySelector (el: HTMLElement, parentSelector: string): Promise<HTMLElement> {
    return new Promise<HTMLElement>((resolve, reject) => {
      while (el.parentElement) {
        if (el.parentElement.matches(parentSelector)) {
          resolve(el.parentElement)
          break;
        }
        el = el.parentElement
      }
      reject()
    })
  }
}
