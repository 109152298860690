





























import {Vue, Component, Prop} from 'vue-property-decorator'
import PreHeader from '@/components/Layout/PreHeader.vue'
import {getParam} from "@/lib/params";
import User from "@/types/User";

@Component({
  components: {PreHeader}
})
export default class Header extends Vue {
  @Prop() user!: User
  bonusProgramPage: string | null = null
  isBonusProgramPageFetched: boolean = false

  beforeMount() {
    getParam('bonus.program.page')
        .then(param => this.bonusProgramPage = param)
        .finally(() => this.isBonusProgramPageFetched = true)
  }
}
