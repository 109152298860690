


















import {Component, Prop, Vue} from 'vue-property-decorator'
import {ProductType} from '@/types/Product/ProductType'
import fetcher from "@/lib/fetcher";
import Counter from "@/components/Interface/Counter.vue";
@Component({
  components: {Counter}
})
export default class CartItem extends Vue {

  @Prop() count: number | undefined
  @Prop() product: ProductType | undefined

  currentCount = this.count != null ? this.count : 1

  get title () { return this.product?.title }
  get price () { return this.product?.price }
  get oldPrice () { return this.product?.oldPrice }
  get pictureUrl () { return fetcher.hostName + this.product?.mainPicture?.url }
  get pictureName () { return this.product?.mainPicture?.name }

  onCountChange (newCount: number) {
    this.currentCount = newCount
    this.$emit('change-item-count', this.product?.id, newCount)
  }

  mounted () {
    setTimeout(() => {CartItem.handleResize()}, 200)
    window.addEventListener('resize', CartItem.handleResize)
  }

  beforeDestroy () {
    window.removeEventListener('resize', CartItem.handleResize)
  }

  private static handleResize() {
    const currentImage = document.querySelector<HTMLElement>('.product-picture-container')
    if (currentImage) currentImage.style.height = `${currentImage.offsetWidth}px`
  }
}
