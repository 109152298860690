








import {Component, Vue, Prop} from "vue-property-decorator";
import YouTube from "./YouTube.vue";
import VK from "./VK.vue";
import OK from "./OK.vue";
import RAW from "./RAW.vue";
import {VideoType} from "@/types/VideoType";
import emitter from "@/emitter";

@Component({
  components: {YouTube, VK, OK, RAW}
})
export default class VideoAdapter extends Vue {
  @Prop() type: VideoType | number | null | undefined
  @Prop() url!: string

  frameId = 'fr-' + new Date().getTime()

  get typeValue () {
    const getInt = (value: any) => parseInt(String(value), 10)
    if (this.type != null && this.type.hasOwnProperty('id'))
      return getInt((this.type as VideoType).id)
    if (typeof this.type === "number")
      return getInt(this.type)
  }

  beforeMount() {
    this.$nextTick(this.fixHeight)
  }

  mounted() {
    window.addEventListener('resize', this.fixHeight)
    emitter.on('need.fix.height', this.fixHeight)
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.fixHeight)
  }

  fixHeight (width: any = undefined) {
    const frame = document.querySelector<HTMLElement>(`[data-frame="${this.frameId}"]`)
    if (frame) {
      const style = window.getComputedStyle(frame);
      const frameWidth = width && typeof width === "number" ? width : parseInt(style['width'], 10)
      const frameHeight = (frameWidth * 9) / 16 // 16:9
      const minHeight = 350
      frame.style.height = (frameHeight > minHeight ? frameHeight : minHeight) + 'px'
    }
  }

  onVideoMounted () {
    this.fixHeight()
    this.$emit('video-mounted', this.frameId)
  }
}
