





















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class AdaptiveTable extends Vue {
  @Prop() noHeader!: boolean
  @Prop() rows!: Array<object>
  @Prop() idIndex!: number
  tableId = 'responsive-table-' + new Date().getTime()
  needHeader = !this.noHeader
  minTableWidth: number = 0

  beforeMount() {
    window.onresize = this.controlTableWidth
  }

  mounted() {
    this.controlTableWidth()
  }

  beforeDestroy() {
    delete window.onresize
  }

  controlTableWidth () {
    const tableContainer = document.querySelector<HTMLElement>('#' + this.tableId)
    const parent = tableContainer?.parentElement
    const table = document.querySelector<HTMLElement>('#' + this.tableId + ' table')

    if (tableContainer && table && parent) {
      const styles = window.getComputedStyle(table)
      const parentStyles = window.getComputedStyle(parent)
      const tableWidth = parseInt(styles.width, 10)
      const parentWidth = parseInt(parentStyles.width, 10)

      if (parentWidth > this.minTableWidth) {
        tableContainer.classList.remove('flat')
      }
      if (parentWidth < tableWidth) {
        this.minTableWidth = tableWidth + 1
        tableContainer.classList.add('flat')
      }
    }
  }
}
