













import {Component, Prop, Vue} from 'vue-property-decorator'
import {NavItem} from '@/types/Sidebar'

@Component
export default class Sidebar extends Vue {

  @Prop() baseUrl: string | undefined
  @Prop() items: Array<NavItem> | undefined

  cleanFullPath (path: string) {
    let formattedFullPath = path
    const lastChar = path.substr(path.length - 1, 1)
    if (lastChar === '/') {
      formattedFullPath = path.substr(0, path.length - 1)
    }
    return formattedFullPath
  }

  goto (url: string) {
    const fullPath = this.fullPath(url)
    if (fullPath !== this.$route.fullPath) this.$router.push(fullPath)
  }

  isCurrentPath (url: string) {
    const currentFullPath = this.cleanFullPath(this.$route.fullPath)
    const incomingFullPath = this.cleanFullPath(this.fullPath(url))
    return incomingFullPath === currentFullPath
  }

  currentClassName (url: string) {
    const classes = ['btn', 'btn-sm', 'w-100', 'sidebar-item']
    if (this.isCurrentPath(url)) classes.push('btn-light')
    return classes.join(' ')
  }

  fullPath (url: string) {
    let baseUrl = this.baseUrl
    if (baseUrl?.substr(baseUrl?.length - 1, 1) !== '/') {
      baseUrl += '/'
    }
    return url.substr(0, 1) === '/' ? url : baseUrl + url
  }
}
