






























import {Component, Vue} from "vue-property-decorator";
import fetcher from "@/lib/fetcher";
import TextEditor from "@/components/Interface/TextEditor.vue";
@Component({
  components: {TextEditor}
})
export default class WeeklyTemplate extends Vue {

  content = ''
  isTemplateFetched = false
  isResponseSuccess = false
  isResponseError = false

  beforeMount() {
    fetcher.get('/mailing-template/weekly_stat_all').then(response => {
      this.content = response.templateHtml
    }).finally(() => this.isTemplateFetched = true)
  }

  onTemplateChange (e: any) {
    this.content = encodeURIComponent(e.html)
  }

  onSubmit () {
    const onSuccess = () => {
      this.isResponseSuccess = true
      setTimeout(() => this.isResponseSuccess = false, 1500)
    }
    const onError = () => {
      this.isResponseError = true
      setTimeout(() => this.isResponseError = false, 1500)
    }

    fetcher.put('/mailing-template/1', fetcher.getJsonForm({ template: this.content }))
        .then(onSuccess)
        .catch(onError)
  }
}
