























import {Component} from "vue-property-decorator";
import Grid from "../../Interface/Grid.vue";
import GridView from "../GridView.vue";
import fetcher from "@/lib/fetcher";

@Component({
  components: {Grid}
})
export default class AdminStat extends GridView {
  isNeedSort: boolean = false
  gridEndpoint: string = '/stat/admin/by-city'
  defaultPageSize = 100
  currentRegion = ''
  showStat = false
  statHeader = 'Кол-во людей в 1-й линии'
  statSwitchHeader = 'Показать кол-во заказов в 1-й линии'
  statItems = [
    {
      'id': '...',
      'email': '...',
      'количество': '...'
    }
  ]

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    // noinspection NonAsciiCharacters
    return {
      'город': entity.city,
      'кол-во': entity.count,
    }
  }

  mounted() {
    const id = setInterval(() => {
      const el = document.querySelector('.grid')
      if (el != null) {
        document.querySelectorAll<HTMLElement>('[data-id]').forEach(row => {
          row.addEventListener('click', () => {
            this.currentRegion = String(row.getAttribute('data-id'));
            this.switchToFirstLine()
          })
        })
        clearInterval(id)
      }
    }, 100)
  }

  switchStat() {
    const switchButton: HTMLElement = this.$refs['switch-button'] as HTMLElement;
    const isFirstLine = switchButton.innerText === 'Показать кол-во заказов в 1-й линии'
    if (isFirstLine) { // меняем на топ 100 по заказам в 1-й линии
      this.switchToOrders()
    } else { // меняем на топ 100 по количеству людей в 1-й линии
      this.switchToFirstLine()
    }
  }

  switchToFirstLine() {
    fetcher.get(`/stat/admin/by-first-line?city=${this.currentRegion}`).then(response => {
      if (response.length > 0) {
        this.statItems = this.statItems.filter((i: {[index: string]: string}) => i['id'] == null)
        response.map((user: {[i: string]: string}) => this.statItems.push({
          id: user.id,
          email: user.email,
          'количество': user.count
        }))
        this.statHeader = 'Кол-во людей в 1-й линии'
        this.statSwitchHeader = 'Показать кол-во заказов в 1-й линии'
        if (!this.showStat) this.showStat = !this.showStat
      } else {
        alert('для данного региона нет статистики')
      }
    })
  }

  switchToOrders() {
    fetcher.get(`/stat/admin/by-orders-in-first-line?city=${this.currentRegion}`).then(response => {
      if (response.length > 0) {
        this.statItems = this.statItems.filter((i: {[index: string]: string}) => i['id'] == null)
        response.map((user: {[i: string]: string}) => this.statItems.push({
          id: user.id,
          email: user.email,
          'количество': user.count
        }))
        this.statHeader = 'Кол-во заказов в 1-й линии'
        this.statSwitchHeader = 'Показать кол-во людей в 1-й линии'
        if (!this.showStat) this.showStat = !this.showStat
      } else {
        alert('для данного региона нет статистики')
      }
    })
  }
}
