















import {Component, Prop, Vue} from "vue-property-decorator";
import {MessageEntity} from "@/types/MessageEntity";
import fetcher from "@/lib/fetcher";
import {ucFirst} from "@/lib/formatter";
import {getFormattedDateTime} from "@/lib/date";

@Component
export default class MessageView extends Vue {
  @Prop() id!: number
  message: MessageEntity | null = null
  isMessageFetched: boolean = false

  get subject () {
    return ucFirst(this.message?.subject ? this.message?.subject : 'Без темы')
  }

  get from () {
    return `${this.message?.from.lastName} ${this.message?.from.firstName}`.trim()
  }

  get fromProfileLink () {
    return `/profile/admin/users/${this.message?.from.id}`
  }

  get to () {
    let full = `${this.message?.to.lastName} ${this.message?.to.firstName}`
    if (this.message?.to.id === 1) full = "Администратор"
    return full.trim()
  }

  get toProfileLink () {
    return `/profile/admin/users/${this.message?.to.id}`
  }

  get whenCreated () {
    return getFormattedDateTime(this.message?.created, "dd.MM.yyyy hh:mm:ss")
  }

  beforeMount() {
    fetcher.get(`/admin/message/${this.id}`)
        .then(message => this.message = Object.assign({}, this.message, message))
        .finally(() => this.isMessageFetched = true)
  }
}
