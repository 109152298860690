



































import {Component, Prop, Vue} from 'vue-property-decorator'
import {OrderEntity, OrderStatusEntity} from "@/types/OrderEntity";
import fetcher from "@/lib/fetcher";
import {Page} from "@/types/Page";
import {OrderForm} from "@/types/OrderForm";
import {getFormattedPhone, ucFirst} from "@/lib/formatter";
import OrderView from "@/components/OrderDetail/OrderView.vue";
@Component({
  components: {OrderView}
})
export default class OrderEdit extends Vue {
  @Prop() id!: number
  order: OrderEntity = {
    id: 0,
    status: '',
    hash: '',
    basket: { id: 0, status: '', compositions: [] },
    buyer: {userId: 0, fullName: '', phone: '', email: '', city: ''},
    orderStatus: {id: 0, status: '', displayValue: '', isDefault: false, value: ''},
    pointsGranted: false,
  }
  statusOptions: Array<{text: string, value: string | number}> = []
  orderForm: OrderForm = {
    id: null,
    orderStatusId: null,
    buyer: null,
  }
  showStatuses: boolean = false
  isOrderFetched: boolean = false
  isUpdateSuccess: boolean = false
  isUpdateError: boolean = false

  get formattedBuyerPhone () {
    return getFormattedPhone(String(this.orderForm.buyer?.phone))
  }

  beforeMount() {
    fetcher.get(`/order/admin/${this.id}`).then((order: any) => {
      this.fillForm(order)
      fetcher.get('/order-status?limit=500').then((statusesPage: Page<OrderStatusEntity>) => {
        statusesPage.content.map(status => { this.statusOptions.push({
          value: status.id,
          text: ucFirst(status.displayValue),
        })})
      }).finally(() => this.showStatuses = true)
    }).finally(() => this.isOrderFetched = true)
  }

  fillForm (order: any) {
    this.order = Object.assign({}, this.order, order)
    const buyer = this.order.buyer
    const orderForm: OrderForm = {
      id: this.order.id,
      orderStatusId: this.order.orderStatus.id,
      buyer: {
        id: buyer.id,
        userId: order.buyer.user.id,
        fullName: String(buyer.fullName),
        phone: String(buyer.phone),
        email: String(buyer.email),
        city: String(buyer.city)
      }
    }
    this.orderForm = Object.assign({}, this.orderForm, orderForm)
  }

  onSubmit () {
    const isNotPayedOrderToPayed = this.order.orderStatus.id !== 2
        && this.orderForm.orderStatusId === 2 && !this.order.pointsGranted

    const attention = 'Вы уверены что хотите перевести статус заказа в "Оплачен"? ' +
        'Это приведёт к начислению баллов за заказ покупателю и трём его линиям сверху пирамиды. Продолжить?'

    if (isNotPayedOrderToPayed && !confirm(attention)) return false

    fetcher.put('/order', fetcher.getJsonForm(this.orderForm)).then((order: any) => {
      this.fillForm(order)
      this.isUpdateSuccess = true
      if (isNotPayedOrderToPayed) location.reload()
      setTimeout(() => this.isUpdateSuccess = false, 1500)
    }).catch(() => {
      this.isUpdateError = true
      setTimeout(() => this.isUpdateError = false, 1500)
    })
  }
}
