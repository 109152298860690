









import {Component, Vue} from "vue-property-decorator";
import UserForm from "@/components/Admin/User/UserForm.vue";
import fetcher from "@/lib/fetcher";
@Component({
  components: {UserForm}
})
export default class CreateUser extends Vue {

  isSaveError: boolean = false
  defaultValues = { 'roles': [1], status: 'ACTIVE' }

  mounted () {
    const nav = document.querySelector<HTMLElement>('.horizontal-btn-nav')
    const last = nav?.querySelector<HTMLElement>('li:last-child')
    last?.classList.add('active')
  }

  onSubmit (fields: any) {
    fetcher.post('/admin/user', fetcher.getJsonForm(fields)).then(result => {
      this.$router.push('/profile/admin/users/' + result.id)
    }).catch(() => {
      this.isSaveError = true
      setTimeout(() => {this.isSaveError = false}, 2000)
    })
  }
}
