
























import {Component, Vue} from 'vue-property-decorator'
import Layout from "@/components/Layout/Layout.vue"
import Breadcrumbs from "@/components/Breadcrumbs.vue"
import {Crumb} from '@/types/Breadcrumbs'
import {getStoredUser, isAdmin} from '@/lib/user'
import emitter from '@/emitter'
import fetcher from '@/lib/fetcher'
import Sidebar from '@/components/Interface/Sidebar.vue'
import {NavItem} from '@/types/Sidebar'
import HorizontalBtnNav from "@/components/Interface/HorizontalBtnNav.vue";
import ProfileNav from "@/types/ProfileNav";

@Component({
  components: {HorizontalBtnNav, Sidebar, Breadcrumbs, Layout}
})
export default class ProfileStat extends Vue {

  name: string = 'Статистика'
  crumbs: Crumb[] = [{ title: 'Мой профиль', to: '/profile' }, {title: this.name}]
  message: { error: boolean, value: string } = {error: true, value: ''}
  lines: any
  isAdmin: boolean = false
  horizontalItems: Array<NavItem> = ProfileNav
  sidebarItems: Array<NavItem> = [
    {
      title: 'Первая линия',
      to: 'stat',
    },
    {
      title: 'Вторая и третья линии',
      to: 'stat/deeper'
    }
  ]

  beforeMount() {
    getStoredUser().then(async (user) => {
      try {
        const json = await fetcher.get('/profile/three-lines-down', {
          headers: new Headers({ 'Authorization': 'Bearer ' + user.accessToken })
        })
        if (json.status.code === 200) {
          this.lines = Object.assign({}, this.lines, json.data)
          this.message.error = false
        } if (json.status.code === 401) {
          emitter.emit('user.logout')
        } else {
          this.message.value = json.message
        }
      } catch (e) {
        console.log(e)
        this.message.value = 'ошибка обращения к серверу'
      }
      this.isAdmin = await isAdmin()
    }).catch(() => {
      this.message.value = 'пользователь не авторизован'
      emitter.emit('user.logout')
    })
  }
}
