























































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Field} from '@/types/Form'
import {getSibling} from '@/lib/dom'
import {FormSelectOption} from "@/types/FormSelectOption";

@Component
export default class Form extends Vue {
  @Prop() allDisabled: boolean | undefined
  @Prop() inputClass: string | undefined
  @Prop() formGroupClass: string | undefined
  @Prop() submitFormGroupClass: string | undefined
  @Prop() submitClass: string | undefined
  @Prop() submitText: string | undefined
  @Prop() submitDisabled: boolean | undefined
  @Prop() fields!: Array<Field>
  @Prop() clearAfterSuccessSubmit!: boolean | undefined
  @Prop() internalSuccess: boolean | undefined

  submitId: string = 'submit-' + new Date().getTime()

  getFieldDefaultClass (type: string) {
    if (type === 'checkbox') return 'form-check'
    else return 'form-group'
  }

  getDefaultInputClass (type: string) {
    if (type === 'checkbox') return 'form-check-input'
    else return 'form-control'
  }

  getDefaultLabelClass (type: string) {
    if (type === 'checkbox') return 'form-check-label'
    else return ''
  }

  getSelectSize (field: Field): number {
    if (field.size != null) return field.size
    return field.value?.length > 5 ? 5 : field.value?.length
  }

  mounted() {
    for (let i in this.fields) {
      if (this.fields.hasOwnProperty(i)) {
        if (this.fields[i].hasErrors == null)
          this.fields[i].hasErrors = () => {return false}
        if (!this.fields[i].id)
          this.fields[i].id = this.fields[i].name
      }
    }
  }

  async checkState(hasErrors: any, field: Field): Promise<boolean> {
    const fieldElement = document.querySelector<HTMLInputElement>('#' + field.id)
    const invalidFeedbackElement = getSibling(<HTMLElement> fieldElement, '.invalid-feedback')

    let errorFlag = false

    if (fieldElement) {
      if (typeof hasErrors === "object") {
        for (let i in hasErrors) {
          if (hasErrors.hasOwnProperty(i)) {
            const errorText = await hasErrors[i](fieldElement.value)
            if (errorText) {
              errorFlag = true
              if (invalidFeedbackElement) invalidFeedbackElement.innerText = errorText
              break
            }
          }
        }
      } else {
        let errorText: string | undefined
        if (fieldElement.type === 'checkbox') {
          errorText = hasErrors(fieldElement.checked)
        } else {
          errorText = hasErrors(fieldElement.value)
        }
        if (errorText) {
          errorFlag = true
          if (invalidFeedbackElement) invalidFeedbackElement.innerText = errorText
        }
      }

      if (!errorFlag) {
        fieldElement.classList.remove('is-invalid')
        fieldElement.classList.add('is-valid')
        this.fields[this.fields.indexOf(field)].valid = true
        setTimeout(() => {
          fieldElement.classList.remove('is-valid')
        }, 1000)
      } else {
        fieldElement.classList.remove('is-valid')
        fieldElement.classList.add('is-invalid')
        this.fields[this.fields.indexOf(field)].valid = false
        setTimeout(() => {
          fieldElement.classList.remove('is-invalid')
        }, 3000)
      }
      let value: any = fieldElement.value
      if (fieldElement.type === 'checkbox') {
        value = fieldElement.checked
      } else if (fieldElement.tagName.toLowerCase() === 'select') {
        value = []
        fieldElement.querySelectorAll<HTMLOptionElement>('option').forEach(option => {
          (value as Array<FormSelectOption>).push({
            label: option.innerText,
            value: String(option.getAttribute('value')),
            selected: option.selected,
          })
        })
      }
      this.fields[this.fields.indexOf(field)].value = value
    }

    return !errorFlag
  }

  async onSubmit () {
    // убедимся что все поля прошли проверки
    let allCorrect = true
    for (let i in this.fields) {
      if (this.fields.hasOwnProperty(i)) {
        const checkResult = await this.checkState(this.fields[i].hasErrors, this.fields[i])
        if (!checkResult) { allCorrect = false }
      }
    }

    const internalSuccess = this.internalSuccess === undefined || this.internalSuccess

    if (!internalSuccess) this.$emit('internal-unsuccessful')

    if (allCorrect && internalSuccess) {
      const fields: { [index: string]: string | undefined } = {}
      this.fields.forEach(field => {
        fields[field.name] = field.value
      })
      if (this.clearAfterSuccessSubmit) this.clearForm()
      this.$emit('submit-form', fields)
    }
  }

  clearForm () {
    this.fields.map(field => field.value = "")
  }
}
