



















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Counter extends Vue {

  @Prop() count: number | undefined | null
  @Prop() min: number | undefined | null
  @Prop() max: number | undefined | null
  @Prop() showTitle: boolean | undefined | null

  currentMin = 1
  currentMax = 100

  currentShowTitle: boolean = true
  elementId = 'counter-' + new Date().getTime()

  currentCount: number = this.currentMin

  get element () {
    return document.querySelector<HTMLInputElement>(`[data-counter-id="${this.elementId}"]`)
  }

  mounted () {
    if (this.min != null) this.currentMin = this.min
    if (this.max != null) this.currentMax = this.max
    if (this.count != null) this.currentCount = this.count
    if (this.showTitle != null) this.currentShowTitle = this.showTitle
  }

  valuesControl () {
    if (this.currentCount <= this.currentMin) {
      this.currentCount = this.currentMin
    } else if (this.currentCount >= this.currentMax) {
      this.currentCount = this.currentMax
    }
  }

  increment () {
    this.currentCount++
    this.valuesControl()
    this.emitChange()
  }

  decrement () {
    this.currentCount--
    this.valuesControl()
    this.emitChange()
  }

  processing () {
    const val = this.element?.value || this.currentMin
    this.currentCount = parseInt(val.toString(), 10)
    this.valuesControl()
    if (this.element != null) {
      this.element.value = this.currentCount.toString()
    }
    this.emitChange()
  }

  emitChange () {
    this.$emit('count-change', this.currentCount)
  }
}
