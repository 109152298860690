import {isAdmin} from "@/lib/user";

export function goLoginIfRouteAuthRequireAndUserIsNotAuthorize (to: any, from: any, next: any) {
    let isAuthorized = !!localStorage.getItem("user")
    let authRequiredRoutes = ['profile', 'profileStat', 'admin']
    let isAuthRequiredRoute = authRequiredRoutes.indexOf(to.name) > -1
    !isAuthorized && isAuthRequiredRoute ? next({name: 'login', params: { back: to.name }}) : next()
}

export function goOutIfNotAdmin (to: any, from: any, next: any) {
    return isAdmin() ? next() : next({name: 'profile'})
}

export function goBackIfUserAuthorize(to: any, from: any, next: any) {
    let isAuthorized = !!localStorage.getItem("user")
    isAuthorized ? next({name: from.name ? from.name : 'home'}) : next()
}

export async function goOAuth2 (to: any, from: any, next: any) {
    let isAuthorized = !!localStorage.getItem("user")
    isAuthorized ? next({name: from.name ? from.name : 'home'}) : next()
}
