






















































import {Component, Prop, Vue} from 'vue-property-decorator'
import UserForProfileStatFirstLine from "@/types/UserForProfileStatFirstLine";
import Feedback from "@/components/Feedback.vue";
@Component({
  components: {Feedback}
})
export default class LineCard extends Vue {
  @Prop() isFirstLine: boolean | undefined
  @Prop() isOtherLine: boolean | undefined
  @Prop() user!: UserForProfileStatFirstLine
  to = {id: this.user.id, name: this.user.name}
}
