import {AssignConstructor} from "@/types/AssignConstructor";

export class Buyer extends AssignConstructor {
    id?: number | null
    userId: number | undefined
    fullName: string | undefined
    phone: string | undefined
    email: string | undefined
    city: string | undefined
}
