


















import {Component} from 'vue-property-decorator'
import GridView from "@/components/Admin/GridView.vue";
import Grid from "@/components/Interface/Grid.vue";
import {getFormattedDateTime} from "@/lib/date";

@Component({
  components: {Grid}
})
export default class ReferralTransactions extends GridView {

  gridEndpoint = '/admin/transactions'

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    return {
      id: entity.id,
      user: `<a href="/profile/admin/users/${entity.userId}">${entity.userId}</a>`,
      sub: entity.subId ? `<a href="/profile/admin/users/${entity.subId}">${entity.subId}</a>` : entity.subId,
      value: entity.value,
      order: `<a href="/profile/admin/orders/${entity.orderId}">${entity.orderId}</a>`,
      status: entity.status,
      date: getFormattedDateTime(entity.created, "dd.MM.yyyy hh:mm:ss")
    }
  }

  mounted () {
    const interval = setInterval(() => {
      const statuses = document.querySelectorAll<HTMLElement>('[data-label="status"]')
      const rows = document.querySelectorAll<HTMLElement>('.table-wrap tr')
      if (statuses.length) {
        this.onStatusesExists(statuses)
        clearInterval(interval)
      }
      if (rows.length === 1) clearInterval(interval)
    }, 10)
  }

  onStatusesExists (statuses: NodeList) {
    statuses.forEach((status: Node) => {
      const el = <HTMLElement> status
      if (el.innerText === 'DELETED') {
        const row = el.closest<HTMLElement>('[data-id]')
        const control = row?.querySelector('[data-label="Действия"]')
        if (control) control.innerHTML = ''
      }
    })
  }
}
