















import {Vue, Component, Prop} from 'vue-property-decorator'
import {Crumb} from '@/types/Breadcrumbs'

@Component
export default class Breadcrumbs extends Vue {
  @Prop() crumbs!: Array<Crumb>
}
