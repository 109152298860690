






import {Component, Vue} from "vue-property-decorator";
import FaqForm from "@/components/Admin/Faq/FaqForm.vue";
import {FaqEntity} from "@/types/FaqEntity";
import {uuid} from "@/lib/string";
import fetcher from "@/lib/fetcher";
@Component({
  components: {FaqForm}
})
export default class FaqCreate extends Vue {
  entity: FaqEntity = {
    id: null,
    uuid: uuid(),
    question: '',
    answer: ''
  }

  onSubmit () {
    fetcher.post('/faq', fetcher.getJsonForm(this.entity))
        .then((f: FaqEntity) => {this.$router.push({name: 'faq-edit', params: {id: String(f.id)}})})
        .catch(() => this.alertResult(String(this.entity.uuid), false))
  }

  alertResult (uuid: string, isSuccess: boolean) {
    const resultVariant = isSuccess ? 'success' : 'danger'
    const resultText  = isSuccess ? 'Сохранено!': 'Непредвиденная ошибка!'
    const btn = document.querySelector<HTMLInputElement>(`#save-faq-${uuid}`)
    if (btn) {
      const originalClasses = btn.getAttribute('class')
      const originalText = btn.innerText
      const resultClasses = `btn btn-${resultVariant}`
      btn.setAttribute('class', resultClasses)
      btn.innerText = resultText
      setTimeout(() => {
        btn.setAttribute('class', String(originalClasses))
        btn.innerText = originalText
      }, 1500)
    }
  }
}
