
















import {Component, Vue} from "vue-property-decorator";
import {getCookie, setCookie} from "@/lib/cookie";
import Agreement from "@/components/Interface/Agreement.vue";
import {getParam} from "@/lib/params";
@Component({
  components: {Agreement}
})
export default class App extends Vue {

  cookieAlertShow: boolean = false

  privacy: string = ''
  usage: string = ''

  async beforeMount () {
    this.usage = await getParam('usage.policy.page')
    this.privacy = await getParam('privacy.policy.page')
    this.checkCookieAgreement();
  }

  checkCookieAgreement () {
    const cookie = 'policy-agree'
    const expires = new Date()
    expires.setSeconds(expires.getSeconds() + (60*60*24*365*100))
    const cookieExists = getCookie(cookie)
    if (!cookieExists) {
      setCookie(cookie, 'true', {expires})
      this.cookieAlertShow = true
    }
  }
}
