

































import {Component, Vue} from "vue-property-decorator";
import {getParam} from "@/lib/params";

@Component
export default class Footer extends Vue {
  offerPage: string = ''
  usagePolicyPage: string = ''
  privacyPolicyPage: string = ''
  isOfferPageFetched: boolean = false
  isUsagePolicyPageFetched: boolean = false
  isPrivacyPolicyPageFetched: boolean = false

  beforeMount() {

    getParam('order.offer.page')
        .then(text => this.offerPage = text)
        .finally(() => this.isOfferPageFetched = true)

    getParam('usage.policy.page')
        .then(text => this.usagePolicyPage = text)
        .finally(() => this.isUsagePolicyPageFetched = true)

    getParam('privacy.policy.page')
        .then(text => this.privacyPolicyPage = text)
        .finally(() => this.isPrivacyPolicyPageFetched = true)
  }
}

