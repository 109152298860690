




























import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/components/Layout/Layout.vue'
import PicturesViewer from '@/components/Product/PicturesViewer.vue'
import ProductCard from '@/components/Product/ProductCard.vue'
import {Crumb} from "@/types/Breadcrumbs";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import fetcher from '@/lib/fetcher';
import {ProductType} from "@/types/Product/ProductType";
import VideoAdapter from "@/components/VideoAdapter/VideoAdapter.vue";

@Component({
  components: {Breadcrumbs, ProductCard, PicturesViewer, Layout, VideoAdapter}
})
export default class Product extends Vue {

  @Prop() code: string | undefined
  product: ProductType | undefined
  isProductFetched = false
  name: string = '...'
  crumbs: Array<Crumb> = [{title: this.name}]

  images: string[] = []

  async fetchProduct () {
    const result = await fetcher.get(`/product/by-code/${this.code}`)
    if (result?.status?.code === 200) {
      this.product = Object.assign({}, this.product, result.data)
      this.setProductName(this.product?.title || 'Без названия')
      const images = []
      if (this.product?.mainPicture) images.push(fetcher.hostName + this.product?.mainPicture.url)
      this.product?.pictures?.map(picture => images.push(fetcher.hostName + picture.file?.url))
      this.images = Object.assign({}, this.images, images)
      this.isProductFetched = true
    } else {
      await this.$router.push('/404')
    }
  }

  setProductName(name: string) {
    this.name = name
    this.crumbs[0].title = name
  }

  beforeMount() {
    this.fetchProduct()
  }

  mounted() {
    setTimeout(() => {Product.handleResize()}, 200)
    window.addEventListener('resize', Product.handleResize)
  }

  onVideoMounted () {
    Product.handleResize()
  }

  private static handleResize () {
    const body = document.querySelector<HTMLElement>('body')
    const video = document.querySelector<HTMLElement>('.product-video')
    const desc = document.querySelector<HTMLElement>('.product-description')

    if (body && video && desc) {
      if (body.offsetWidth > 767) {
        desc.style.height = `${video.offsetHeight}px`
      } else {
        desc.style.height = `auto`
      }
    }
  }
}
