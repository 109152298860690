export class ErrorMessage {
    active: boolean = false
    text: string | null = null
}

export class LoginForm {
    username: string | null = null
    password: string | null = null
    token: string | null = null
}
