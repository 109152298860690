export function getFormattedPhone (phone: string | undefined): string {

    if (phone == null || phone.length === 0) return ''

    phone = String(phone).replace(/[^0-9]/g, '')
    let hasPrefix = phone.substring(0, 1) == "7" || phone.substring(0, 1) == "8"
    if (hasPrefix) {
        phone = phone.substring(1)
    }

    phone = phone.substr(0, 10)

    const phoneFragments = [
        "+7 " + phone.substr(0, 3),
        phone.substr(3, 3),
        phone.substr(6, 2),
        phone.substr(8, 2),
    ]
    return phoneFragments.join(' ')
}

export function getCleanPhone (phone: string): string {
    return getFormattedPhone(phone).replace(/[^0-9]/g, '')
}

export function ucFirst(str: string) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}

export function toUriJson (params: any) {
    return encodeURIComponent(JSON.stringify(params))
}

export function tryParseJSON (params: any) {
    try {
        return JSON.parse(params)
    } catch (ignored) {}
}
