












import {Component, Prop, Vue} from "vue-property-decorator";
import {FaqEntity} from "@/types/FaqEntity";
import TextEditor from "@/components/Interface/TextEditor.vue";

@Component({
  components: {TextEditor}
})
export default class FaqForm extends Vue {

  @Prop() entity!: FaqEntity

  get entityAnswer () {
    return this.entity.answer
  }

  onSubmit () {
    this.$emit('submit', this.entity)
  }

  onAnswerChange (e: any) {
    this.entity.answer = encodeURIComponent(e.html)
  }
}
