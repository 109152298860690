























import {Component, Prop, Watch} from "vue-property-decorator";
import {SelectFormElement} from "@/types/SelectFormElement";
import GridView from "@/components/Admin/GridView.vue";
import {getFormattedDateTime} from "@/lib/date";
import Grid from "@/components/Interface/Grid.vue";

@Component({
  components: {Grid}
})
export default class StatByPeriod extends GridView {
  @Prop() period!: string
  isNeedSort: boolean = false
  localPeriod: string = this.period.toLowerCase()
  gridEndpoint: string = '/stat/by-period/' + this.localPeriod.toUpperCase()

  getGridEndpoint (): string {
    return '/stat/by-period/' + this.localPeriod.toUpperCase()
  }

  periods: Array<SelectFormElement> = [
    {
      text: 'Дни',
      value: 'day',
    },
    {
      text: 'Недели',
      value: 'week',
    },
    {
      text: 'Месяцы',
      value: 'month',
    },
    {
      text: 'Года',
      value: 'year',
    }
  ]

  beforeMount() {
    this.onPeriodChange()
  }

  fetchMapping(entity: { [p: string]: any }): { [p: string]: any } {
    // noinspection NonAsciiCharacters
    return {
      '#': entity.num,
      'начало периода': getFormattedDateTime(entity.periodStart, "dd.MM.yyyy"),
      'кол-во': entity.count,
    }
  }

  @Watch('localPeriod')
  onPeriodChange () {
    if (this.period !== this.localPeriod) {
      this.$router.push({
        name: String(this.$route.name),
        params: {period: this.localPeriod}
      })
      this.gridEndpoint = this.getGridEndpoint()
      this.fetchPage()
    }
  }
}
