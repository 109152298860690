//@ts-ignore
import Quill from 'quill'

export class QuillConfigurer {
    static configure () {
        const Video = Quill.import('formats/video');
        class CustomVideo extends Video {
            //@ts-ignore
            static create(value) {
                let video = document.createElement('IFRAME')
                video.setAttribute('frameborder', '0');
                video.setAttribute('allowfullscreen', String(true));
                //@ts-ignore
                video.setAttribute('src', this.sanitize(value));
                video.className = 'ql-video'

                let wrapper = super.create(value)
                wrapper.appendChild(video)
                return wrapper;
            }
        }
        Video.className = 'ql-video-wrapper';
        Video.tagName = 'DIV';
        Quill.register(CustomVideo, true);

        const Image = Quill.import('formats/image')
        class CustomImage extends Image {

            static width: {[index: string]: string} = {}

            //@ts-ignore
            static create(value) {
                let image = document.createElement('IMG')
                if (typeof value === 'string') {
                    //@ts-ignore
                    image.setAttribute('src', this.sanitize(value));
                    image.className = 'ql-image'
                    //@ts-ignore
                    image.style.width = this.width[this.hashCode(this.sanitize(value))] || ''
                    const tmp = document.createElement('DIV')
                    tmp.innerHTML = value
                }
                const wrapper = super.create()
                wrapper.appendChild(image)
                return wrapper
            }

            static value(domNode: any) {
                const img = domNode.querySelector('.ql-image')
                const width = img?.style?.width
                //@ts-ignore
                const src = this.sanitize(img?.getAttribute('src'))
                const hash = this.hashCode(String(src))
                this.width[String(hash)] = width
                return domNode.getAttribute('src');
            }

            static hashCode(str: any) {
                return str.split('').reduce((prevHash: any, currVal: any) =>
                    (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
            }
        }
        //@ts-ignore
        CustomImage.className = 'ql-image-wrapper'
        //@ts-ignore
        CustomImage.tagName = 'SPAN'
        Quill.register(CustomImage, true);
    }
}
