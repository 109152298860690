






import {Component, Prop, Vue} from "vue-property-decorator";
import fetcher from "@/lib/fetcher";

@Component
export default class PaymentSuccess extends Vue {
  @Prop() orderId: string | undefined

  statusText = 'подождите...'

  beforeMount() {
    const transactionId = this.$route.query.payment_id
    if (!transactionId) { this.statusText = 'Ошибка: транзакция не передана'; return }

    fetcher.get(`/payment/transaction/${transactionId}`).then(res => {
      if (res.status === 'success' || res.status === 'obtained' || res.status === 'stuck') {
        this.$router.push('/order-detail/' + res.orderid)
      } else {
        this.statusText = 'Ошибка: платеж не проведен до конца или завершился ошибкой'
      }
    }).catch(() => this.statusText = 'Ошибка: не удалось получить корректный ответ от сервера')
  }
}
