




import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class YouTube extends Vue {
  @Prop() url: string | undefined

  get formattedUrl () {

    let code: string | undefined | null = ''
    const url = new URL(this.url || '')
    const params = new URLSearchParams(url.search)
    if (url.pathname.match('/watch')) {
      code = params.get('v')
    } else if (url.origin.match('youtu.be') || url.pathname.match('/embed')) {
      code = url.pathname.split('/').pop()
    }

    return 'https://www.youtube.com/embed/' + code
  }

  mounted() {
    this.$emit('video-mounted')
  }
}
